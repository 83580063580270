import './TextBox.css';

function TextBox({title, text, size}) {

    return (
        <div width={size} className="TextBox">
            <div className="TextBoxTitle"><h3 className="TextBoxTitleText">{title}</h3></div>
            <p className="TextBoxText">{text}</p>
        </div>
    );
}

export default TextBox;