import './Infobox.css'
import InfoBoxDataSection from './InfoboxDataSection.js';

function InfoboxNation(props){
    return(
        <div class="InfoBox">

        <header class="InfoBoxHeading">
            <h3>{props.nomeoficial}</h3>
        </header>

        <div class="InfoBoxImg">
            <img width="75%" src={props.bandeiraSrc} alt={`${props.nome} flag`}/>
        </div>

        <div class="InfoBoxHeading">
            <h3>Informações Básicas</h3>
        </div>

        <div id="InfoBoxData">
        <InfoBoxDataSection title="Capital" content={props.capital} />
        <InfoBoxDataSection title="Governo" content={props.governo} />
        <InfoBoxDataSection title="Religião" content={props.religiao} />
        <InfoBoxDataSection title="Governante" content={props.governante} />
        <InfoBoxDataSection title="População" content={props.populacao} />
        <InfoBoxDataSection title="Moeda" content={props.moeda} />
        <InfoBoxDataSection title="IDH" content={props.idh} />
        <InfoBoxDataSection title="Idioma" content={props.idioma} />
      </div>
        <div class="InfoBoxHeading">
            <h3>Mapa</h3>
        </div>

        <div class="InfoBoxImg">
        </div>
    </div>
    )
}

export default InfoboxNation