function InfoBoxDataSection({title, content, flagSrc}){
    return(
        <div class="InfoBoxDataSection">
            <div class="InfoBoxDataTitle">
                {title}
            </div>
            <div class="InfoBoxDataContent">
                {content}
            </div>
        </div>
    );
}

export default InfoBoxDataSection