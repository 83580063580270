import './App.css';
import { BrowserRouter as Router, Link } from 'react-router-dom';

import Logo from './components/Header/Logo';
import ThemeSwitcher from './components/Header/ThemeSwitcher';

import RoutesCentral from './pages/routesCentral';

function App() {
  return (
    <div className="App">
      <Router>
        <header>
          <Link style={{ textDecoration: 'none' }} to="/"><Logo /></Link>

          <div style={{ textAlign: 'center', flexDirection: 'column' }}>
            <span style={{ fontSize: 'large', textAlign: 'center' }}>Versão 0.1.? <br /> (Ainda em desenvolvimento!)<br /></span>
            <Link style={{ textDecoration: 'none', fontSize: 'large', color: 'var(--font-link-color)' }} to="/changelog">Veja a Changelog</Link>
          </div>

          <Link to="/pagelist">
            <button className="Button">
              Lista de Páginas
            </button>
          </Link>

          <ThemeSwitcher />

        </header>

        <div id="PageBody">
          <RoutesCentral />
        </div>
      </Router>
    </div>
  );
}

export default App;
