import './Infobox.css';
import InfoBoxDataSection from './InfoboxDataSection.js';
import InfoboxCountry from './InfoboxCountry.js';

function InfoboxBattle({ title, imageSrc, leg, data, localizacao, resultado, beligerantesLado1, beligerantesLado2, comandantesLado1, comandantesLado2, forcasLado1, forcasLado2, baixasLado1, baixasLado2 }) {
    return (
        <div className="InfoBox">
            <header className="InfoBoxHeading">
                <h3>{title}</h3>
            </header>

            <div className="InfoBoxImg">
                <img width="75%" src={imageSrc} alt={`${title} image`} />
                <br />
                <span>{leg}</span>
            </div>

            <div className="InfoBoxHeading">
                <h3>Informações Básicas</h3>
            </div>

            <div id="InfoBoxData">
                <InfoBoxDataSection title="Data" content={data} />
                <InfoBoxDataSection title="Localização" content={localizacao} />
                <InfoBoxDataSection title="Resultado" content={resultado} />
            </div>

            <div className="InfoBoxHeading">
                <h3>Beligerantes</h3>
            </div>

            <div className="InfoBoxSides">
                <div className="InfoBoxCountryList">
                    {beligerantesLado1.map((pais, index) => (
                        <InfoboxCountry key={index} flagSrc={pais.flagSrc} to={pais.to} linkText={pais.nome} />
                    ))}
                </div>
                <div className="InfoBoxCountryList">
                    {beligerantesLado2.map((pais, index) => (
                        <InfoboxCountry key={index} flagSrc={pais.flagSrc} to={pais.to} linkText={pais.nome} />
                    ))}
                </div>
            </div>

            <div className="InfoBoxHeading">
                <h3>Comandantes</h3>
            </div>

            <div className="InfoBoxSides">
                <div className="InfoBoxCountryList">
                    {comandantesLado1.map((comandante, index) => (
                        <InfoboxCountry key={index} flagSrc={comandante.flagSrc} to={comandante.to} linkText={comandante.nome} />
                    ))}
                </div>
                <div className="InfoBoxCountryList">
                    {comandantesLado2.map((comandante, index) => (
                        <InfoboxCountry key={index} flagSrc={comandante.flagSrc} to={comandante.to} linkText={comandante.nome} />
                    ))}
                </div>
            </div>

            <div className="InfoBoxHeading">
                <h3>Forças</h3>
            </div>

            <div className="InfoBoxSides">
                <div className="InfoBoxCountryList">
                    {forcasLado1.map((forca, index) => (
                        <InfoboxCountry key={index} flagSrc={forca.flagSrc} to={forca.to} linkText={forca.quantidade} />
                    ))}
                </div>
                <div className="InfoBoxCountryList">
                    {forcasLado2.map((forca, index) => (
                        <InfoboxCountry key={index} flagSrc={forca.flagSrc} to={forca.to} linkText={forca.quantidade} />
                    ))}
                </div>
            </div>

            <div className="InfoBoxHeading">
                <h3>Baixas</h3>
            </div>

            <div className="InfoBoxSides">
                <div className="InfoBoxCountryList">
                    <span>{baixasLado1}</span>
                </div>
                <div className="InfoBoxCountryList">
                    <span>{baixasLado2}</span>
                </div>
            </div>
        </div>
    );
}

export default InfoboxBattle;
