import '../page.css';

import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';
import MainArticle from '../../components/ArticleTitles/MainArticle';
import ArticleRedirect from '../../components/ArticleTitles/ArticleRedirect';
import Radio from '../../components/MiscBoxes/Radio';

import nationRoutes from '../nationPages';
import eventRoutes from '../eventPages';

import { images } from '../../components/Images/ImagesSrc';



function Minuano() {
  return (
    <div>

      <PageTitle title="Minuano" />
      <InfoboxNation nome="minuano"
        bandeiraSrc={images.minuanoFlag}
        nomeoficial="Confederação Imperial de Minuano"
        capital="Magnópolis"
        governo="Monarquia Eletiva"
        religiao="Catolicismo"
        governante="Alberto I de Córdoba e Luna"
        populacao="52,7 Milhões"
        moeda="Ducato Imperial"
        idh="-"
        idioma="Alto Minuanense e Baixo Minuanense" />

      <div className='PageContent'>
        <p>
          A Confederação Imperial de Minuano localiza-se no Norte do Planeta, principalmente no continente de
          Ipiranga. Composta de diversos principados, repúblicas e estados eclesiásticos, se assemelha ao antigo Sacro
          Império Romano Germânico da Terra. Sua parte continental chamada de Península da Costa Verde é limitada pela
          <ArticleRedirect to="/federacaobrasilica"> Federação Brasílica</ArticleRedirect> a leste e pelo Mar de Minuano
          a Norte, Sul e Oeste. Suas principais ilhas são Guarapuava, Registro e Cornualha.
        </p>

        <p>
          Foi fundada logo após a primeira Dieta Imperial, que ocorreu pouco tempo após o fechamento dos portais. Abrangendo
          diversas ilhas e arquipélagos pelo Norte, a Confederação se expandiu rapidamente por meios coloniais, sendo uma
          das grandes potências do planeta, com a maior marinha do mundo e uma economia forte.
        </p>

        <PageTitle title="História" level={2} />

        <p>
          No ano de 1995 (6 AF), após a abertura dos portais, grandes oportunidades surgiram no planeta Pindorama, e diversas
          pessoas decidiram iniciar uma nova vida em um novo lugar. De todas as classes e etnias, diversos colonos chegaram
          ao continente de Ipiranga e procederam a criar assentamentos na região que ficaria conhecida como Península da Costa Verde.
        </p>

        <p>
          Grandes nomes como os von Richtofen, Rademaker e Dória investiram pesadamente nas colônias, fazendo com que adiquirissem poder
          político, outros grandes nomes surgiram das colônias mais prósperas, como os Pinheiros. A Igreja Católica também foi responsável
          por parte do desenvolvimento local, estabelecendo novas dioceses e organizando a estrutura regional para promover a Fé Católica.
          Pessoas de classe baixa também tentaram melhorar suas vidas na região, formado pequenas repúblicas camponesas.
        </p>

        <p>
          A Península da Costa Verde se estabeceu como uma região rica e próspera. Porém no ano de 2001 (0 AF) os portais se fecharam,
          cortando a conexão entre a Terra e Pindorama. Após avaliar a situação, as lideranças locais se reuniram na cidade de São José do
          Paraná e fundaram a Confederação Imperial de Minuano e suas instituições como a Dieta Imperial e o Conselho Eleitoral, composto por:
        </p>


        <ul>
          <li>Eliana Rademaker do Principado de Rademaker</li>
          <li>João Dória do Principado de Dória</li>
          <li>Manfred von Richtofen do Ducado de Richtofen</li>
          <li>Josevaldo Carlos Peres do Bispado de Caxias</li>
          <li>Roberto Pereira do Bispado de São José do Paraná</li>
          <li>Marcelo Moura do Bispado de Campinas-no-Itiberê</li>
        </ul>


        <p>
          A Confederação formou também Círculos Imperiais baseados nas colônias com cultura similar, como o Círculo Imperial Hispaneiro, que
          foi majoritariamente formado por colônias com descententes de espanhóis, chamados de Hispaneiros.
        </p>

        <PageTitle title="Era do Declínio Tecnológico (0 - 100)" level={3} />

        <p>
          Com o fechamento dos portais, novas tecnologias cessaram e os equipamentos se desgastando e avariando, muitas tecnologias foram perdidas
          ao tempo. Eventualmente a geração com o conhecimento da Terra foi gradualmente reduzida. O Planeta sobreviveu ao declínio, porém gravemente
          enfraquecido e com pouco conhecimento, tecnologias regrediram a níveis medievais.
        </p>


        <p>
          Alguns principados da Confederação com economias baseadas em tecnologias, como os Richtofen ou os Dória sofreram com o enfraquecimento de suas
          economias, a Confederação também viu sua infraestrutura decair, com viadutos e pontes colapsando por falta de manutenção. Após o quase colapso
          da nação, uma nova sociedade feudal havia surgido no lugar da sociedade empreendedora, com patrões sendo substituídos por lordes.
        </p>


        <p>
          Por volta do ano 50, boa parte da infraestrutura já havia desaparecido, prédios foram abandonados e boa parte da população recuou para regiões do
          então interior, que rapidamente viu pequenas comunidades agrárias surgindo. Comunidades, que eventualmente se tornariam cidades.
        </p>

        <PageTitle title="Idade Média" level={3} />

        <p>
          Margarete I de Diamantina foi eleita Imperatriz da Confederação no ano de 102, e sua postura reformista iniciaram o processo da reformulação do
          governo Imperial, e estabeleceu privilégios especiais para territórios nas fronteiras da Confederação, que foram chamado de Magraviatos e entregues
          aos ambiciosos que almejavam expandir ambas suas terras como os limites da Confederação. O primeiro Magraviato foi estabelecido na fronteira da
          <ArticleRedirect to="/federacaobrasilica"> Federação Brasílica</ArticleRedirect> e entregue ao General Marcelo Barros Castanheto.
        </p>

        <p>
          Margarete I também viu a formação da Liga Mercante de Minuano pelas cidades de Nova Lubeque e Nova Hamburgo, que receberam o título de Cidades Livres
          como forma de impulsionar o poder de comércio
        </p>

        <PageTitle title="Terceira Era de Instabilidade" level={3} />

        <p>
          A morte inesperada do Imperador Conrado III em 551 deu fim a Feira de Ciências e Tecnologia, e a Dieta Imperial deu início aos processos de Eleição Imperial,
          porém o Conselho Eleitoral de Minuano falhou em eleger um candidato, causando uma crise de sucessão ao trono imperial. Três candidatos principais se destacaram
          sendo o Príncipe Dráusio de Umuarama vindo do rico porém pequeno Principado de Umuarama, O Príncipe-Eleitor Saulo I de Rademaker vindo do tradicional principado
          naval de Rademaker, e o Príncipe-Eleitor Alberto IV de Córdoba e Luna, sendo descendente do lendário General Alberto de Luna.
        </p>


        <p>
          Os três candidatos procuraram apoio internacional para conseguir influência no Conselho Eleitoral. Dráusio de Umuarama se aliou com uma delegação de
          <ArticleRedirect to="/guararapes"> Guararapes</ArticleRedirect>, Saulo de Rademaker conseguiu apoio Baependiano e Alberto de Córdoba foi apoiado por
          <ArticleRedirect to="/montesclaros"> Montes Claros</ArticleRedirect> e <ArticleRedirect to="/vasterras"> Vasterras </ArticleRedirect>
        </p>

        <PageTitle title="Intervenção em Guararapes" level={4} />

        <MainArticle text="Ver artigo principal:" to="../intervencao_em_guararapes" linkText="Intervenção em Guararapes" />

        <p>
          Após a Defenestração de Magnópolis o Imperador imediatamente declarou mobilização total e um bloqueio naval ao território costeiro de Guararapes
        </p>

        <PageTitle title="Rádio" level={2} />

        <Radio src="https://open.spotify.com/embed/playlist/2kf4QVIsc3mjBIodE0WfoU?utm_source=generator"/>
      </div>
    </div>
  );
}

export default Minuano;