import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';

function ReinoMundial() {
    return (
        <div>
            <PageTitle title="Reino Mundial do Poder de Deus" />

            <InfoboxNation
                nome="Reino Mundial"
                bandeiraSrc={images.rmpdFlag}
                nomeoficial="Reino Mundial do Poder de Deus"
                capital="-"
                governo="Teocracia"
                religiao="Protestantismo Evangélico"
                governante="-"
                populacao="-"
                moeda="-"
                idh="-"
                idioma="Português">
            </InfoboxNation>
        </div>
    );
}

export default ReinoMundial;