import { flagSrc } from './FlagSrc'

export const images = {

    ...flagSrc,

    pindoramaFoto: require('../../assets/img/pindorama foto.png'),

    barragemArtilhariaHarmonense: require('../../assets/img/maps/barragem nova harmonia.png'),

    igrejaNossoSenhorJesusCristoBaepensysys: require('../../assets/img/nationimg/baependy/igreja nosso senhor jesus cristo de baepensysys.png'),

    atk: require('../../assets/img/profilepictures/ATKNK.png'),
    carreta: require('../../assets/img/profilepictures/Carreta.png'),
    deyvid: require('../../assets/img/profilepictures/Deyvid.png'),
    empire: require('../../assets/img/profilepictures/Empire.png'),
    roraima: require('../../assets/img/profilepictures/Roraima.png'),
    zees: require('../../assets/img/profilepictures/Zees.png'),
}