import React from "react";
import './Header.css';

import { images } from "../Images/ImagesSrc";

function Logo() {
    return (
        <div className="Logo">
            <img id="LogoImg" width={90} height={90} src={images.pindoramaFoto} />
            <b id="LogoText">Enciclopædia<br />Pindorâmica</b>
        </div>
    );
}

export default Logo;