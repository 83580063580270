import React from "react";
import Aeterna from "./nations/aeterna";
import Aratigia from "./nations/aratigia";
import Baependy from "./nations/baependy";
import Bradualia from "./nations/bradualia";
import Catania from "./nations/catania";
import Ceruletauria from "./nations/ceruletauria";
import EstadoIracema from "./nations/estadoiracema";
import FederacaoBrasilica from "./nations/federacaobrasilica";
import Guararapes from "./nations/guararapes";
import ImperioCarsilico from "./nations/imperiocarsilico";
import Minuano from "./nations/minuano";
import MontesClaros from "./nations/montesclaros";
import NovaBangu from "./nations/novabangu";
import NovaCambria from "./nations/novacambria";
import NovaCarapicuiba from "./nations/novacarapicuiba";
import NovaHarmonia from "./nations/novaharmonia";
import NovaPetropolis from "./nations/novapetropolis";
import Potiguaro from "./nations/potiguaro";
import Quaqasa from "./nations/quaqasa";
import ReinoMundial from "./nations/reinomundial";
import RepublicaDelVale from "./nations/republicadelvale";
import RepublicaIracema from "./nations/republicairacema";
import Ribati from "./nations/ribati";
import SaoCarmo from "./nations/saocarmo";
import TerceiraEra from "./nations/terceiraera";
import Vasterras from "./nations/vasterras";
import VaziosPolares from "./nations/vaziospolares";

const nationRoutes = [
    { path: "/aeterna", element: <Aeterna /> },
    { path: "/aratigia", element: <Aratigia /> },
    { path: "/baependy", element: <Baependy /> },
    { path: "/bradualia", element: <Bradualia /> },
    { path: "/catania", element: <Catania /> },
    { path: "/ceruletauria", element: <Ceruletauria /> },
    { path: "/estadoiracema", element: <EstadoIracema /> },
    { path: "/federacaobrasilica", element: <FederacaoBrasilica /> },
    { path: "/guararapes", element: <Guararapes /> },
    { path: "/imperiocarsilico", element: <ImperioCarsilico/>},
    { path: "/minuano", element: <Minuano /> },
    { path: "/montesclaros", element: <MontesClaros /> },
    { path: "/novabangu", element: <NovaBangu /> },
    { path: "/novacambria", element: <NovaCambria /> },
    { path: "/novacarapicuiba", element: <NovaCarapicuiba /> },
    { path: "/novaharmonia", element: <NovaHarmonia /> },
    { path: "/novapetropolis", element: <NovaPetropolis /> },
    { path: "/potiguaro", element: <Potiguaro /> },
    { path: "/quaqasa", element: <Quaqasa /> },
    { path: "/reinomundial", element: <ReinoMundial/>},
    { path: "/republicadelvale", element: <RepublicaDelVale /> },
    { path: "/republicairacema", element: <RepublicaIracema /> },
    { path: "/ribati", element: <Ribati /> },
    { path: "/saocarmo", element: <SaoCarmo /> },
    { path: "/terceiraera", element: <TerceiraEra /> },
    { path: "/vasterras", element: <Vasterras /> },
    { path: "/vaziospolares", element: <VaziosPolares /> }
];

export default nationRoutes;
