import React from "react";
import ThemeButton from "./ThemeButton";

import LightIcon from '../../assets/svg/icons/lightIcon.svg';
import DarkIcon from '../../assets/svg/icons/darkIcon.svg';
import PindoramaIcon from '../../assets/svg/icons/pindoramaIcon.svg';

import { themeClassic, themeDark, themePindoramaBlue } from "../../scripts/themes";

function ThemeSwitcher(){
    return(
        <div>
            <ThemeButton icon={LightIcon} onClick={themeClassic} altText={"Tema Claro"}/>
            <ThemeButton icon={DarkIcon} onClick={themeDark} altText={"Tema Escuro"}/>
            <ThemeButton icon={PindoramaIcon} onClick={themePindoramaBlue} altText={"Tema Pindorama"}/>
        </div>
    );
}

export default ThemeSwitcher;