import PageTitle from "../../components/ArticleTitles/PageTitle"

function Changelog() {
    return (
        <div>
            <PageTitle title="Changelog" />
            <hr/>

            <div>
                <PageTitle title="0.1.1" level={2} />
                12 de Setembro de 2024

                <h4>Adições</h4>
                <ul>
                    <li>Enciclopædia Pindorâmica lançada</li>
                    <li>Páginas de Minuano, Montes Claros, Vasterras e Vazios Polares adicionadas</li>
                    <li>Modelo base de InfoBox para nações, eventos e pessoas criado</li>
                    <li>Temas implementados</li>
                    <li>Rotas implementadas</li>
                </ul>

                <hr/>


                <PageTitle title="0.1.2" level={2} />
                16 de Setembro de 2024

                <h4>Adições</h4>
                <ul>
                    <li>Changelog adicionada</li>
                    <li>Página de Baependy escrita (obrigado Empire!)</li>
                    <li>Modelo de citações criado</li>
                    <li>Rádio adicionada a Minuano</li>
                </ul>

                <h4>Mudanças</h4>
                <ul>
                    <li>Páginas de Nova Harmonia, Potiguaro e Nova Bangú com mais conteúdo</li>
                    <li>Ajustes menores no espaçamento</li>
                </ul>

                <hr/>


                <PageTitle title="0.1.3" level={2} />
                26 de Setembro de 2024

                <h4>Adições</h4>
                <ul>
                    <li>Datas adicionadas a Changelog</li>
                    <li>Página dos Administradores adicionada</li>
                    <li>Página de Alberto I de Minuano adicionada</li>
                    <li>Lista de Todas as Páginas adicionada</li>
                </ul>

                <h4>Mudanças</h4>
                <ul>
                    <li>Acessibilidade melhorada</li>
                    <li>Modelo novo de Changelog com Adições, Mudanças e Ajustes</li>
                    <li>Mais conteúdo para a página de Baependy (obrigado Empire!)</li>
                </ul>

                <h4>Ajustes</h4>
                <ul>
                    <li>Cor da Changelog corrigida</li>
                    <li>Correção no tamanho da Rádio</li>
                </ul>

                <hr/>

                <PageTitle title="0.1.?" level={2} />
                07 de Outubro de 2024

                <h4>Adições?</h4>
                <ul>
                    <li>01000010 01100001 01110011 01100101 00110110 00110100 </li>
                </ul>

                <h4>Mudanças?</h4>
                <ul>
                    <li>??Lista de Páginas??</li>
                </ul>

                <hr/>

            </div>

            


        </div>
    );
}

export default Changelog