export const flagSrc = {
    aeternaFlag: require('../../assets/img/flags/aeterna.png'),
    aratigiaFlag: require('../../assets/img/flags/aratigia.png'),
    baependyFlag: require('../../assets/img/flags/baependy2.png'),
    bradualiaFlag: require('../../assets/img/flags/bradualia.png'),
    cataniaFlag: require('../../assets/img/flags/catania.png'),
    ceruletauriaFlag: require('../../assets/img/flags/ceruletauria.png'),
    estadoiracemaFlag: require('../../assets/img/flags/estadoiracema.png'),
    federacaobrasilicaFlag: require('../../assets/img/flags/federacaobrasilica.png'),
    guararapesFlag: require('../../assets/img/flags/guararapes2.png'),
    quaqasaFlag: require('../../assets/img/flags/quaqasa.png'),
    imperiocarsilicoFlag: require('../../assets/img/flags/imperiocarsilico.png'),
    minuanoFlag: require('../../assets/img/flags/minuano.png'),
    montesclarosFlag: require('../../assets/img/flags/montesclaros.png'),
    novabanguFlag: require('../../assets/img/flags/novabangu.png'),
    novacambriaFlag: require('../../assets/img/flags/novacambria.png'),
    novacarapicuibaFlag: require('../../assets/img/flags/novacarapicuiba.png'),
    novaharmoniaFlag: require('../../assets/img/flags/novaharmonia.png'),
    novapetropolisFlag: require('../../assets/img/flags/novapetropolis.png'),
    potiguaroFlag: require('../../assets/img/flags/potiguaro.png'),
    republicadelvaleFlag: require('../../assets/img/flags/republicadelvale.png'),
    republicairacemaFlag: require('../../assets/img/flags/republicairacema.png'),
    ribatiFlag: require('../../assets/img/flags/ribati.png'),
    rmpdFlag: require('../../assets/img/flags/rmpd.png'),
    saocarmoFlag: require('../../assets/img/flags/saocarmo.png'),
    terceiraeraFlag: require('../../assets/img/flags/terceiraera.png'),
    vasterrasFlag: require('../../assets/img/flags/vasterras.png'),
    vaziospolaresFlag: require('../../assets/img/flags/vaziospolares.png')
}