import ArticleRedirect from '../ArticleTitles/ArticleRedirect'
import './memberbox.css'

function MemberBox(props) {
    return (
        <div className="MemberBox">

            <div className='MemberBoxTitle'>
                <span className='MemberBoxName'>{props.nome}</span>

                <ArticleRedirect to={props.to}>
                    <img className='Flag' src={props.flag} width="7%" />
                </ArticleRedirect>
            </div>

            <div className='MemberBoxFunction'>
                <span>{props.funcao}</span>
            </div>

            <img className='MemberBoxImage' width="50%" src={props.img} />

            <p className="MemberBoxDescription">
                {props.desc}
            </p>

            <div className="MemberBoxMsg">
                “<span>{props.msg}</span>”
            </div>
        </div>
    )
}

export default MemberBox