import './misc.css'

function Radio(props){
    return(
        <div className='Radio'>
            <iframe className='RadioPlaylist' src={props.src} allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"/>
        </div>
    )
}

export default Radio