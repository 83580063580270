import './Infobox.css'
import ArticleRedirect from '../ArticleTitles/ArticleRedirect';

function InfoboxCountry({ name, flagSrc, to, linkText}){
    return(
        <div class="InfoBoxCountry">
            
            <ArticleRedirect to={to}>
                <img width={25} src={flagSrc} alt={`${linkText} flag`}/>
                <span>{linkText}</span>
                </ArticleRedirect>
        </div>
    );
}

export default InfoboxCountry