export function themeClassic(){
    document.body.className = 'themeClassic';
}

export function themeDark(){
    document.body.className = 'themeDark';
}

export function themePindoramaBlue(){
    document.body.className = 'themePindoramaBlue';
}