import './Article.css'

function PageTitle({title, level = 1}){
    const Tag = `h${level}`;

    return(
        <div class="PageTitle">
            <Tag>{title}</Tag>
        </div>
    );
}

export default PageTitle;