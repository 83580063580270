import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';
import Quote from '../../components/TextBox/Quote';

function Baependy() {
  return (
    <div>
      <PageTitle title="Baependy" />

      <InfoboxNation
        nome="Baependy"
        bandeiraSrc={images.baependyFlag}
        nomeoficial="Reino de Baependy"
        capital="São Lucas"
        governo="Monarquia"
        religiao="Catolicismo"
        governante="João I Pinheiro Lobo"
        populacao="42 Milhões"
        moeda="Reyss Baepenysys"
        idh="0,780"
        idioma="Alto Baependiano e Portuneiro">
      </InfoboxNation>

      <PageTitle title="História" level={2} />

      <PageTitle title="Primeiros Anos" level={3} />
      <p>
        Com a Chegada da Tecnologia dos Portais na Terra
        Os Brasileiros viram uma ótima oportunidade para colonizar
        um planeta e enriquecer a nação, durante o Governo do então presidente ‘’Fernando Henrique Cardoso’’  ele incentivou os estados
        a colonizarem várias partes do planeta que estava sendo descoberto seu nome foi dado como ‘’Pindorama’’ , um dos principais estados
        a começarem a colonizar pindorama foi Minas Gerais com cidades como Baependi e Montes Claros, Baependy sendo a Primeira Cidade do Sul
        de Minas Gerais a colonizar o planeta de Pindorama com o apoio do Prefeito da cidade
      </p>

      <p>
        O Prefeito da Cidade resolveu apoiar um grupo de colonizadores Que estava ganhando muita popularidade em Baependi, os chamados ‘’Nova Esperança
        Brasileira’’-NEB, Um Grupo de Jovens Adultos Monarquistas baependianos que não aceitavam a Derrota do Plebiscito da Monarquia do Brasil em 1993,
        eles foram convocados pelo Governo de Baependi para colonizarem o planeta novo o que não só atraiu baependianos como também pessoas ao redor de
        outras cidades como São Lourenço,Caxambu,Varginha,São João del Rei,Passa Quatro e muitos outros, um grupo de 50 famílias foram convocadas para
        colonizarem e catalogarem o local, a primeira vista a colônia da Nova Esperança Brasileira se viu num bioma de Savana sendo muito quente para
        os padrões dos brasileiros Entretando como o planeta era desconhecido ainda eles resolveram fazer a colônia ali mesmo próximo da praia.
      </p>

      <p>
        Mas foi durante o Governo de ‘’Fernando Souza de Marlston’’ (1999-2003) que ele deu uma impulsionada na Colonia Enviando diversos suprimentos
        para lá que não conseguia se sustentar sozinha sem a ajuda do governo brasileiro no ainda governo do Fernando Henrique Cardoso, enviando comida,
        armas para defesa pessoal contra os animais hostis da savana, barcos para os pescadores amadores que conseguiam sustentar a colônia por algum
        certo tempo, e muito em breve o prefeito de Baependi pretendia enviar até carros para colônia afim de construir estradas no futuro, o que acabou
        não indo pra frente devido a outro acontecimento.
      </p>

      <PageTitle title="Progressão da Colônia" level={4} />

      <p>
        Durante a colonização do território local o governo da Cidade de Baependi, Tanto o Prefeito Frederico de Fonseca Cardoso e Fernando Souza de Marlston
        durante 1995 e 2001, A Colônia da Nova Esperança Brasileira se via em Dificuldades Para criar assentamentos no local devido a baixa quantidade de árvores
        devido a região que o portal levou os baependianos que por ser uma Região de Savana era difícil de criar as primeiras casas E muitos deles tinham que
        importar concreto do brasil sendo caro Pra colonizar mas era necessário pois era a única coisa que o governo podia oferecer para construção de casas,
        no começo as casas seriam pequenas de apenas 1 andar e poucos cômodos pois a colônia ainda era muito pequena e estava crescendo de pouco em pouco e não
        podiam simplesmente pegar tudo que tinham em suas casas na Terra. e durante os anos de 1995 a 2000, 1 ano antes do portal fechar. A Colônia da Nova Esperança
        Brasileira fez a uma das primeiras construções na nova colônia, uma Igreja foi construída como forma de Homenagear a Cidade e também como os portugueses
        fizeram quando vieram colonizar o brasil em 1500.
      </p>

      <p>
        A Progressão da Colônia ia muito bem com o Prefeito Fernando fazendo pesados investimentos sobre a colônia como por exemplo construir as primeiras casas mesmo
        que seja pequenas mas isso era apenas temporário, já que durante o seu governo a colônia ainda não tinha infraestrutura se limitando a apenas estradas feitas
        de terra. nos anos 2000 a Colônia de Baependy já abrigava cerca de 2500 pessoas muitos desses vindos de outras cidades mas a maioria sendo nativa de Baependy.
        Esse boom populacional que se deu na colônia foi a descoberta de ouro em algumas regiões da colônia. Além de ouro também foram encontrados outros minérios como
        prata, cobre, ferro e nióbio. Essa descoberta fez com que o prefeito de baependy fizesse campanha para incentivar as pessoas da cidade e de cidades vizinhas a
        irem até a colônia interplanetária para trazerem seus recursos e fazer com que o brasil crescesse e prosperasse, mas o destino tinha outras prioridades e uma
        dessas prioridades fizeram com que o destino fosse terrível para os Baependyanos.
      </p>

      <PageTitle title="Fechamento dos Portais" level={3} />

      <p>
        Após o portal se fechar em 2001, a colônia se encontrava em uma situação terrível e avassaladora, os portais sumiram de repente e a colônia se encontrava em
        fragilidade com muitas pessoas indo a beira da loucura tentando encontrar formas de fazer os portais aparecerem de novo para voltarem pra terra, mas eles nunca
        mais se abririam. O Fundador da Colônia vendo que a situação era terrível para a Colônia ele reuniu os seus melhores homens e convocou todos eles em uma reunião
        na Igreja de Jesus Cristo de Baepensysy e lá o Lucas Pinheiro (Fundador da Colônia) se proclamou o Líder de Baependy. Afim de conter as loucuras e revoltas da
        População de Baependy, ele reuniu toda a população da colônia em frente a igreja, dizendo para eles não continuarem com as atitudes que eles estavam fazendo pois
        aquilo não ia trazer os portais de volta e que só ia piorar a situação, ele disse também para nunca entrarem em pânico pois eles nunca estariam sozinhos, pois
        cristo sempre estaria lá por eles assim como ele se sacrificou por nós para salvar nossas almas de pecados terríveis. Desde esse dia as revoltas e loucuras das
        pessoas pararam mesmo em que menor escala. Lucas pinheiro como líder de baependy não sabia como a colônia ia ser no futuro e temendo que o nome do antigo brasil
        fosse perdido por eles e também de sua cidade natal na terra resolveu anotar tudo em um diário para não esquecerem de nada e resolveu deixar ele nas mãos dos padres
        da única igreja da colônia, o padre ‘’Antonio Humbertus de Israel’’ se encarregaria de proteger o povo das paranoias que os consumiam a cada dia. Lucas Pinheiro
        Governou baependy até os seus 67 anos no Ano 29.
      </p>

      <p>
        Após a morte do Líder da Colônia seu sucessor seria o Tiago Almeira Pinheiro de Maria, porem antes que ele pudesse liderar a colônia no ano 30 ele foi traído pelos
        militares que eram encarregados de proteger a colônia dos perigos que assolavam a Nova Esperança Brasileira. Ele foi exilado para a península acima da colônia que
        naquele ano estava se expandindo rumo ao norte, Tiago e sua família foram exilados na então região denominada de ‘’Ararynya’hs’’ que nos dias de hoje é denominada de
        ‘’Perdizes’’.
      </p>

      <p>
        Tiago tentou negociar com os Militares dizendo que se ele os liderasse ele poderia oferecer conforto a eles e ao povo. Porém ele foi ignorado e foi exilado mesmo assim
        e no seu exilio ele e sua família testemunhariam o nascimento da nação de biapisia (atualmente Baependy). Porem o que mais entristeceu Tiago foi que o governo era
        liderado apenas por militares ou seja uma ditadura.
      </p>

      <p>
        Tiago morreu no ano 45 aos 75 anos e ele não viveria para ver sua colônia ser grande no futuro outra coisa que ele não sabia, era que sua família iria liderar Biapisia
        no futuro como uma Monarquia.
      </p>

      <p>
        As suas ultimas palavras antes de morrer foram:
      </p>

      <Quote quote="Que Deus proteja essa jovem nação" author="Tiago Pinheiro"></Quote>

      <PageTitle title="O Nascimento da Escravidão" level={3} />

      <p>
        Nos primeiros meses do ano 50 a fome começava a assolar a população de Biapisia com muitos morrendo de desnutrição.
      </p>

      <p>
        O Governo, para tentar evitar que mais pessoas morressem de desnutrição e dentre outras coisas, resolveu tomar uma atitude muito cruel e nefasta. A Escravidão era instituída,
        usando os Neo-Indios e pessoas pobres como forma de aumentar os estoques de alimentos e de produção para a população. Independente de sua cor, eles seriam escravizados se os
        mesmos tivessem uma renda muito baixa.
      </p>

      <p>
        A escravidão marcaria a história de Biapisia, sendo um dos períodos mais terríveis e tristes para aqueles que seriam escravizados. Aqueles que tentassem resistir à
        escravização forçada seriam mortos sem nenhum pudor ou misericórdia.
      </p>

      <p>
        A Igreja não gostou do que fizeram com aqueles que eram escravizados, dizendo que Cristo nunca faria isso com aqueles que ele considerava como seus Filhos. O Governo disse a
        eles para calarem a boca ou até os padres seriam escravizados se tentassem algo contra eles.
      </p>

      <p>
        Naquele momento já estava óbvio que o governo tinha total controle sobre a igreja. Entretanto, eles poderiam continuar com suas missas, desde que não tentassem nada contra o
        governo tirânico do Ditador Carlos Oliveira.
      </p>

      <PageTitle title="A Colonização de Nova Biapisya" level={3} />

      <p>
        No ano 70, no então governo de Ricardo Malassu, ele resolveu enviar uma expedição ao redor do planeta Pindorama para encontrar um lugar mais adequado ao povo de Biapisya,
        já que devido ao calor do país muitos sofriam com a insolação e com as plantas constantemente morrendo devido ao sol quente do bioma de savana. Os primeiros navios a vela
        da nação começavam a ser construídos com a ajuda dos escravizados.
      </p>

      <p>
        Após a construção do navio, ele seria usado com vários outros ao seu redor para explorarem Pindorama. Suas primeiras viagens foram feitas ao redor da Península de Biapisya,
        e foi um sucesso a sua trajetória, tendo seu devido reconhecimento pelas viagens. Foi durante essa primeira viagem que os militares começaram a colonizar o Norte da Península
        e as ilhas acima dela. Em uma dessas viagens, eles começaram a viajar a leste de Biapisya, chegando num outro bioma de savana no continente de Ipiranga.
      </p>

      <p>
        Durante os anos 72 a 115, muitos colonos biapisiensys se aventuraram pela região do Ipiranga, e foi durante a colonização de Nova Biapisya que eles conheceram o Povo
        Harmonense, que se demonstraram ser muito gentis. Eles também explicaram para tomarem cuidado ao sul do continente, pois ali havia uma nação ainda desconhecida para os
        biapisiensys, denominada "Aeterna Brasiliense", que era um local extremamente hostil e perigoso, onde qualquer um que entrasse na região deles seria morto.
      </p>

      <p>
        Após alguns anos, depois de a nação de Biapisya colonizar sua região no Ipiranga e encontrar recursos valiosos, o governo se vê disposto a aumentar seu domínio sobre a região,
        a fim de expandir as fazendas e a extração de minérios para o crescimento econômico da nação, que estava começando a reformar sua administração, principalmente na área militar.
        No então governo do ditador Marcelo de Andradeas, ele se sente obrigado a expandir o domínio de Biapisya sobre os indígenas na região, que estavam causando problemas para os
        agricultores locais próximos de suas fronteiras. Então, ele toma a atitude de iniciar a "Guerra da Reunificação Baependyana".
      </p>

      <ul>
        <li> Biapisya x</li>
        <li>República Livre de Cuyaba’hs</li>
        <li>Tribo Goyytaquenzyas</li>
        <li>Tribo Amelanhyas</li>
        <li>Tribo PavésCumes</li>
        <li>Tribo São Lourencya’hss</li>
        <li>Cidade Livre de São Martynh’uss</li>
        <li>Cidade Estado de Aparecyda’s</li>
        <li>Cidade Estado de Guarulhi’as</li>
        <li>Estado Livre de Caruarhyas</li>
      </ul>

      <p>
        Apesar de Biapisya estar lutando contra nove nações, os exércitos de seus inimigos eram despreparados e muito ineficazes. A batalha contra essas nove nações, a
        maioria composta por tribos indígenas socialmente bem avançadas, porém, seus destinos eram inevitáveis. A primeira batalha foi contra a República Livre de Cuyaba’hs,
        que durante 20 anos de guerra de reunificação deu muita dor de cabeça para Biapisya, pois suas grandes muralhas eram quase impenetráveis.
      </p>

      <p>
        Durante os anos de 99 a 119, a nação de Biapisya havia mostrado seu domínio perante todas as outras regiões. As tribos indígenas foram as primeiras a caírem, uma
        após outra, e na batalha final, a Batalha de Cuyaba’hs, no dia 13/05/119, as tropas de Biapisya adentraram a capital da República de Cuyaba’hs e incendiaram tudo
        ao seu redor até chegarem no castelo e capturarem o presidente do país, encerrando assim a "Guerra de Reunificação Baependyana".
      </p>

      <PageTitle title="Estagnação econômica" level={3} />

      <p>
        Com a vitória de Biapisya sobre todas as regiões ao redor, a nação finalmente se reunificava, anexando todos os nove países. Porém, tudo isso teve um grande custo: a nação de Biapisya contraiu uma enorme dívida econômica, tão grande que não era capaz de pagar o salário de muitos soldados ou de seus superiores. Biapisya tentaria de tudo para cortar gastos, mas isso só enfureceu os soldados, que queriam seus devidos salários. Afinal, a nação de Biapisya ficou 20 anos em guerra, e desses 20 anos, 17 foram apenas para destruir e anexar a República de Cuyaba’hs.
      </p>

      <p>
        O ditador Roberto Fonseca precisava de sugestões por parte de seus generais. Foi aí que o General Auberthus de Labattutes sugeriu o seguinte a Roberto:
      </p>

      <Quote
        quote="Senhor Roberto, por que nós não vendemos aquela nossa colônia de Nova Biapisya para Nova Harmonia? Acredito que eles pagariam bem para ter aquele  território pra eles."
        author="Auberthus" />

      <Quote
        quote=" Vender Nova Biapisya para os Harmonenses? É uma ideia interessante, mas e enquanto aos colonos que vivem lá? Eles terão a cidadania harmonense e serão bem tratados?"
        author="Roberto" />

      <Quote
        quote="Olha, senhor, quando eles se apresentaram para nós lá na colônia a primeira vez, não demonstraram hostilidade. Eles até respeitam as nossas fronteiras e nos 
      retribuíram com a informação sobre uma nação tão hostil quanto nossos inimigos."
        author="Auberthus" />

      <Quote
        quote="Bom... se esse for o caso, prepare o meu navio então, vamos negociar com eles sobre essa venda! Eu quero quitar logo essa nossa dívida. "
        author="Roberto"
      />

      <p>
        O ditador Roberto foi considerado um herói em todo o território nacional, marcando o "Nascimento de Biapisya", que no futuro seria renomeado para "Baependy".
      </p>

      <PageTitle title="Perseguições aos Nativos" level={3} />

      <p>
        Durante os anos de 130 a 150, o governo do então tirânico Luiz Costa patrocinou a perseguição dos nativos ao sul de Biapisya, com o objetivo de aumentar a mão de
        obra escrava das regiões ao norte, favorecendo os grandes fazendeiros. No sul de Biapisya, após a dominação dos povos nativos locais, foi descoberto que a região a
        sudoeste de Biapisya, onde hoje se encontra a Província Real de Avelinópolis, era bem fértil, o que era perfeito para a agricultura.

      </p>

      <p>
        Com o clima temperado da região, o cultivo de café e uvas tornou-se viável. Logo, entre os anos 130 e 145, o governo de Biapisya incentivou a população a habitar as
        regiões ao sul e sudoeste, com foco especial nos grandes fazendeiros, que teriam grandes lucros com essa nova área temperada. Outro incentivo governamental foi destinado
        aos pescadores, principalmente aqueles que viviam em regiões quentes, como o bioma de savana e tropical nas ilhas a leste de Biapisya.

      </p>

      <p>
        Durante essa mesma época de escravização em Biapisya, a Igreja começou a catequizar os nativos, com o intuito de apagar as religiões pagãs nas regiões sudoeste, sul e leste,
        que eram vistas como aberrações e profanas. A miscigenação também teve início nesse período, com grandes fazendeiros se casando com as nativas, desde que elas abandonassem
        suas antigas tradições, principalmente as de suas religiões profanas.
      </p>

      <p>
        Quando os filhos e filhas desses casamentos nasciam, alguns fazendeiros lhes concediam liberdade, livrando-os da escravidão. Contudo, outros não faziam isso, preferindo
        manter a mão de obra em vez de se casarem com elas.
      </p>

      <PageTitle title="Primeira onda de Grandes Navegações" level={3} />

      <p>
        Durante 30 anos, a nação de Biapisya resolveu apostar nas grandes navegações para descobrir se havia mais nações além das que Biapisya já sabia que existiam, como
        Terceira Era, Ribati, Nova Harmonia e Nova Carapicuíba. Antes dos anos 150, durante os anos de 150 a 180, dezenas e dezenas de navios foram construídos para fins de
        exploração por toda Pindorama, e foi com isso que Biapisya descobriu novas nações, não só ao redor de Pancas, mas também no continente de Ipiranga.
      </p>

      <PageTitle title="Segunda onda de Grandes Navegações" level={3} />

      <p>
        Após o fim das grandes navegações no horizonte sul de Pindorama Era hora de explorar o norte do planeta, os navios usados para explorar o sul do planeta com aqueles que
        voltavam de suas viagens iriam ser reaproveitados para explorar o norte.
      </p>

      <PageTitle title="Medo geral sob Biapisya" level={3} />

      <p>
        Durante os anos de 210 a 300, o governo ditatorial de Biapisya foi marcado por intensos rumores de que os militares congressistas estavam tramando um golpe contra os
        ditadores. Muitos desejavam o poder e conspiravam para tomar o controle do governo. Henrique Araújo, que governou Biapisya de 210 a 230, foi um dos mais afetados por
        essa atmosfera de conspiração. A paranoia o consumiu, e ele se isolou completamente em seu castelo, evitando qualquer contato com o mundo exterior.

      </p>

      <p>
        Henrique raramente saía de sua sala, acreditando que sua vida estava em constante perigo. Ele impôs uma rígida regra de segurança: apenas aqueles que obtinham sua
        permissão podiam entrar em sua sala, sob o risco de serem mortos caso desobedecessem. Esse comportamento lembrava as táticas paranoicas de líderes históricos, como
        Stalin. Ao longo de seu reinado, Henrique sobreviveu a várias tentativas de assassinato, mas, em 230, seu destino foi selado quando ele foi envenenado por seu próprio
        cozinheiro, Lucas Maria. Lucas recebeu uma grande fortuna para cometer o crime, mas até hoje não se sabe quem financiou o assassinato.

      </p>

      <p>
        O medo da morte e da traição se espalhou pelos governos subsequentes. Os ditadores que vieram depois de Henrique viviam em constante vigilância, temendo que também
        fossem assassinados. Eles reforçaram suas guardas pessoais e passaram a supervisionar seus próprios cozinheiros, numa tentativa de evitar que suas refeições fossem
        envenenadas. Esse clima de desconfiança e medo dominou Biapisya até o último governo ditatorial, liderado por Carlos da Fonseca, que governou de 290 a 317, durante 27 anos.
      </p>

      <p>
        O que Carlos não sabia, porém, era que os dias da república estavam contados...
      </p>

      <PageTitle title="A Abolição da Escravidão" level={3} />

      <p>
        O ano de 310 foi marcado como um ano milagroso para os Baependyanos e Baependyanas. Dois anos antes, em 308, começou uma grande revolta entre os escravos, que estavam
        cansados de sofrer. Foram 240 anos de angústia e dor, e a hora de acabar com a escravidão havia chegado. O Padre Luís Silva de Baependy, aproveitando-se dos rumores da
        revolta, utilizou sua eloquência para mobilizar a população em apoio ao fim da escravidão, não apenas na capital, mas também em estados como Avelinópolis, onde havia uma
        grande concentração de escravos.
      </p>

      <p>
        Luís não só inspirou os cidadãos comuns, mas também jovens militares de 18 anos a se rebelarem contra as atrocidades da Ditadura de Biapisya. Ele conquistou a simpatia
        de políticos, especialmente da influente Família Pinheiro Lobo, que governava o estado de Cachinaoa. Quando o governo soube da mobilização, os políticos sugeriram ao
        ditador Carlos Fonseca que mandasse matar Luís. Contudo, sua popularidade era tão grande que tal ato poderia desencadear ainda mais revoltas, potencialmente levando a
        uma guerra civil. Assim, os políticos decidiram deixar Luís em paz, mesmo diante de suas duras críticas ao governo.
      </p>

      <p>
        No ano de 310, Carlos observava da janela de seu castelo uma onda de manifestantes de todas as cores e raças protestando contra a escravidão. Equipados com tochas, lanças,
        tridentes e facões, eles exigiam o fim da opressão. Os militares tomaram posição em frente ao castelo para proteger o ditador e seus conselheiros. Foi então que o Padre Luís
        gritou:
      </p>

      <Quote
        quote="Senhor Carlos Fonseca!! Em nome de Cristo, apareça aqui agora ou sofrerá consequências!! E não é isso que você e nós queremos!!"
        author="Padre Luís Silva" />

      <Quote
        quote="O que vocês querem!?"
        author="Carlos Fonseca" />

      <Quote
        quote="Desejamos o fim da escravidão!! Essas pobres almas não merecem mais esse sofrimento!! Faça a abolição ou nós atacaremos em nome do Senhor!!"
        author="Padre Luís Silva" />

      <p>
        O coração de Carlos pulsava de medo, ciente de que não tinha outra escolha senão abolir aquilo que sustentava a economia de Biapisya. Assim, no dia 22 de outubro
        de 310, a escravidão finalmente chegou ao fim. Os 240 anos de sofrimento haviam chegado ao fim. Dias depois, toda a nação de Biapisya celebrou a abolição, com muitas
        igrejas organizando festas. Homens, mulheres, crianças e idosos finalmente foram libertados das garras dos grandes oligarcas das fazendas.
      </p>

      <p>
        Entretanto, a medida não agradou a todos. Com o fim da mão de obra barata e a falta de indenizações, muitos oligarcas faliram, enquanto aqueles que conseguiram
        sobreviver começaram a criticar o governo. Assim, um clima de descontentamento crescia, e muitos desejavam aquilo que a Família Pinheiro Lobo mais temia: o fim da República!
      </p>

      <PageTitle title="Queda da República" level={3} />

      <p>
        O ano de 317 marcou o início de uma nova era para Biapisya. Após o fim da escravidão, ocorrido há mais de sete anos, os grandes fazendeiros que ainda não haviam
        sido indenizados pela república começaram a financiar os militares que eram contrários ao regime e desejavam um novo tipo de governo, liderado por pessoas que
        realmente se importassem com o povo. Esses fazendeiros, então, financiaram os militares e a marinha em busca de um candidato ideal para derrubar o ditador Carlos
        Fonseca. Foi assim que encontraram Tiago Pinheiro Lobo, um major aposentado que residia em Cachinaoa.
      </p>

      <p>
        Quando esse major aposentado foi encontrado, uma proposta lhe foi feita: derrubar o ditador e instaurar um novo governo em Biapisya. Tiago, percebendo a intenção
        por trás do convite, perguntou:
      </p>

      <Quote
        quote="Deixe-me adivinhar, senhor Rodrigo: você quer instaurar um novo modelo governamental, não é? Um modelo que eu conheço?"
        author="Tiago Pinheiro Lobo" />

      <Quote
        quote="Exatamente, senhor Tiago. Imagino que você seja um homem letrado e conheça um modelo governamental chamado Monarquia."
        author="Rodrigo" />

      <Quote
        quote="Era exatamente isso que eu queria saber. Deixe-me adivinhar: o senhor quer que eu derrube Carlos Fonseca e que instauramos uma Monarquia?"
        author="Tiago Pinheiro Lobo" />

      <Quote
        quote="Isso mesmo, senhor. Como Vossa Mercê deve saber, o povo biapisyense já não aguenta mais esse governo falido. Nós e meus colegas escolhemos você 
      e sua família para liderar nossa nação. Precisamos de um líder competente que realmente se importe com o povo, algo que esses tiranos nunca fizeram."
        author="Rodrigo" />

      <Quote
        quote="Bem, se é isso que o povo quer, então eu topo."
        author="Tiago Pinheiro Lobo" />

      <p>
        Assim, no dia 4 de janeiro de 317, os militares invadiram o castelo do ditador, pegando todos de surpresa. Quando Tiago chegou à sala do ditador, liderando
        seus homens, ele rendeu Carlos Fonseca. Este, surpreso, exclamou:
      </p>

      <Quote
        quote="Tiago!? Por que Tiago!? Logo você, meu mais fiel!"
        author="Carlos Fonseca" />

      <Quote
        quote="Desculpe, senhor Carlos. Não estou fazendo isso por mim ou por você; isso é pelo povo! Essa maldita república morrerá hoje!"
        author="Tiago Pinheiro Lobo" />

      <p>
        Sem opções, Carlos se rendeu aos militares sob o comando de Tiago. Nos dias seguintes, os militares governaram Biapisya como uma Junta Militar Provisória.
        O grande dia em que o povo poderia decidir um novo modelo de governo chegou: 20 de janeiro de 317. O plebiscito foi aberto para todos, e com o apoio do Padre
        Luís e do futuro Rei de Biapisya, Tiago I, discursos foram feitos para difamar a república e suas atrocidades, especialmente no que diz respeito à escravidão.
      </p>

      <p>
        Tiago discursou para milhares de pessoas em frente ao castelo governamental, onde Carlos e seus políticos estavam presos. Ele convenceu a população a experimentar
        um novo tipo de governo, onde um líder realmente se importava com o povo. Com a promessa de uma monarquia e uma família real bem-educada e de caráter, as expectativas
        para a nação eram altas. O povo biapisyense, ciente da ótima reputação da Família Pinheiro em Cachinaoa, gritou: “VIVA O REI! VIVA A MONARQUIA!”
      </p>

      <p>
        Surpreso e animado, Tiago viu que as pessoas estavam ansiosas por essa mudança. Aproveitando o momento, gritou:
      </p>

      <Quote
        quote="VIVA A MONARQUIA! MORTE À REPÚBLICA!"
        author="Tiago Pinheiro Lobo" />

      <p>
        E o povo respondeu em coro: “VIVA! VIVA! A MONARQUIA! VIVA AO REI!” Após muita gritaria e glória, a grande votação se aproximava.
      </p>

      <ul>
        <li>República: 23%</li>
        <li>Monarquia: 77%</li>
      </ul>

      <p>
        Após os resultados saírem o povo festejou mais uma vez. Dia após dia de muita festa e alegria, A República morreu e uma nova era para Biapisya havia começado!!
      </p>

      <PageTitle title="Tiago I" level={3} />

      <p>
        Após o fim da república, Tiago I foi coroado rei de todos os biapisyenses, e sua governança começou com o objetivo de agradar a todos. Uma de suas primeiras medidas
        foi alterar o nome de Biapisya para Baependy, em homenagem ao Padre Luís e aos escravos libertos que ele ajudou a libertar. Essa mudança também visava desvincular o
        governo da antiga república. Entre as ações implementadas por Tiago como Rei dos Baependyanos, destacam-se:
      </p>

      <ul>
        <li>Prisão de todos os políticos que desejavam a volta da república.</li>
        <li>Aumento significativo nos investimentos na educação.</li>
        <li>Fortalecimento da Marinha Real Baependyana.</li>
        <li>Expulsão de todos os diplomatas catâneses de Baependy.</li>
        <li>Militarização ainda maior da fronteira com Ribati.</li>
        <li>Promoção do comércio mais livre, especialmente com Nova Harmonia, Minuano, Vasterras e Nova Carapicuíba.</li>
        <li>Criação da Província de Mucambo.</li>
        <li>Expansão sobre as ilhas ao norte de Baependy.</li>
        <li>Reeducação dos militares que eram contra a monarquia.</li>
        <li>Incentivo à música e às artes.</li>
        <li>Construção de mais estradas para as províncias mais afastadas.</li>
        <li>Erguimento de mais igrejas nas regiões indígenas.</li>
        <li>Reforma nas leis.</li>
        <li>Prisões em massa de prisioneiros.</li>
        <li>Aumento salarial para fazendeiros e policiais.</li>
      </ul>

      <p>
        O primeiro reinado de Tiago I foi considerado razoável, conseguindo corrigir muitos erros cometidos pela república de Biapisya séculos antes. Ele promoveu o fim
        do "Positivismo" da época e encarcerou a população catânesa que residia em Baependy, a qual estava causando problemas para os jovens locais ao oferecer drogas e
        prejudicar muitas famílias. A partir desse momento, Baependy cortou todo e qualquer tipo de relação diplomática com a Catânia; qualquer tentativa de acordo seria
        respondida com o afundamento de navios que adentrassem a região costeira de Baependy, que considerava a Catânia como "escória imunda de Pindorama".
      </p>

      <p>
        Durante seu reinado, Tiago I começou a se aproximar de nações que considerava "dignas", como Nova Harmonia e Nova Carapicuíba. Seu governo foi considerado promissor,
        embora um tanto "mediano". O título real que lhe foi atribuído, "O Reformista", reflete suas reformas no governo e nas leis, tornando a administração de Baependy mais
        eficiente. Em 320, foi anunciada a morte do último ditador de Baependy, que faleceu aos 76 anos devido a um infarto.
      </p>

      <PageTitle title="Tiago II" level={3} />

      <p>
        Após a morte de seu pai, Tiago I, o reinado de Tiago II de Baependy começou em 337. A cerimônia de coroação ocorreu em 22 de maio do mesmo ano. Embora Baependy não
        tivesse uma coroa oficial, o novo rei optou por usar uma faixa simples roxa, adornada com ramos de trigo. O segundo reinado se caracterizou por profundas reformas
        constitucionais e econômicas em todo o país. Tiago II incentivou os ex-escravos a trabalharem na infraestrutura nacional, oferecendo em troca a promessa de suas próprias
        casas. Esse movimento foi de grande importância para a reintegração dos ex-escravos na sociedade.
      </p>
      <p>
        Assim como seu pai, Tiago II manteve os princípios de governança e continuou com os projetos que seu pai não conseguiu concluir em vida, incluindo:

      </p>
      <ul>
        <li>Projeto "Minha Residência, Minha Família"</li>
        <li>Projeto "Libyysya’hs", uma cidade criada para ajudar ex-escravos</li>
        <li>Criação da Província Real de Nhandutiba, nos anos finais do reinado, em 375</li>
        <li>Aumento do financiamento para a Marinha Real Baependyana</li>
        <li>Construção de fortalezas ao redor da península</li>
        <li>Incentivo e financiamento para a medicina</li>
        <li>"Corrida do Ouro em Mucambo"</li>
      </ul>

      <p>
        O reinado de Tiago II foi marcado por um grande progresso, especialmente na área da diplomacia. Ele buscou aproximar Baependy de nações como Nova Harmonia e Minuano,
        estabelecendo contatos frequentes para acordos econômicos e incentivo à imigração, atraindo imigrantes para trabalharem nas grandes fazendas e na pesca.
      </p>

      <p>
        Baependy também manteve relações diplomáticas e comerciais neutras com países como Bradualia, devido ao fim da abolição da escravidão. Embora o país não comprasse mais
        escravos, passou a vender seus prisioneiros mais perigosos para Bradualia.
      </p>

      <p>
        O reinado de Tiago II chegou ao fim em 11 de junho de 377, quando sua majestade faleceu calmamente em sua cama numa noite tranquila. Tiago II ficou conhecido como "O Diplomata".
      </p>

      <PageTitle title="Rafael I" level={3} />

      <p>
        Após a morte de Tiago II, iniciava-se o reinado de Rafael I, um dos mais longos da história de Baependy. Seu governo foi marcado pelo início da industrialização do país durante a segunda era da Revolução Industrial, com investimentos significativos em diversas áreas da indústria. Um dos marcos do seu reinado foi a construção dos primeiros navios a vapor, a partir dos primeiros anos de 410, substituindo gradualmente os antigos navios movidos a vela. Com a era do vapor, os navios passaram a navegar sem depender dos ventos.

      </p>
      <p>
        Rafael I também impulsionou a construção de diversas ferrovias em todo o país, embora em menor escala, já que as locomotivas ainda eram relativamente desconhecidas para os baependyanos. A Locomotiva número 1, construída em 439, estava localizada na província de Tunuí, na capital da província.

      </p>
      <p>Durante seu reinado, Rafael I implementou novas leis e adaptou as antigas legislações dos reinados anteriores de Tiago I e II para os desafios do século. Isso incluiu a criação de leis mais rígidas contra criminosos e a imposição de regras mais estritas para a entrada de estrangeiros em Baependy, visando impedir a imigração de povos que ele considerava "indesejáveis". Ele também começou a construir escolas públicas em todo o país, proporcionando educação de qualidade para aqueles que não podiam pagar.</p>

      <p>Em termos de segurança, Rafael introduziu medidas para proteger trabalhadores em empregos perigosos, como a exigência do uso de capacetes para mineradores, além de reduzir o uso de lamparinas de querosene, uma vez que as províncias de Mucambo e Monte-Alegre possuíam reservas significativas de gás natural.</p>

      <p>Na área da diplomacia, Rafael I aproximou-se de Montes Claros, incentivando a população local a trabalhar nas indústrias em crescimento de Baependy, oferecendo cidadania em troca. </p>

      <p>
        No entanto, seu reinado também enfrentou desastres. Em 15 de novembro de 402, um terremoto de magnitude 7,5 atingiu a província de Santa Aparecida, resultando na morte de mais de 75 pessoas e ferindo 25, além de destruir mais de 45% da capital da província, denominada "Maria". Seu governo também enfrentou tensões diplomáticas com o Ribati e a Catânia.
      </p>

      <p>
        Nos seus últimos anos, Rafael I investiu na construção dos primeiros sistemas de tratamento de esgoto em Baependy, buscando amenizar as mortes por doenças e aliviar a sobrecarga nos hospitais. Em seus últimos dias, ele expressou sua gratidão a Deus por uma vida longa e significativa. Após 75 anos de reinado e já debilitado aos 96 anos, ele não conseguia mais se manter em pé.
      </p>

      <p>
        Rafael I faleceu em 28 de junho de 452, às 15:22, encontrado em seu trono. Inicialmente, pensaram que ele descansava, mas quando seu herdeiro Vicente se aproximou para acordá-lo, encontrou-o sem vida. Seu olhar expressava um pequeno sorriso, com um colar de crucifixo segurado em suas mãos. Vicente, em lágrimas, reconheceu que seu pai partira em paz e tranquilidade. Seu corpo foi sepultado no dia 3 de julho de 452, na igreja de São Joãun, localizada no coração da capital, São Lucas. Rafael I ficou conhecido como "O Ancião" ou "O Veterano".
      </p>

      <PageTitle title="Vicente I" level={3} />

      <p>
        O 4º reinado, diferente do 3º reinado de Rafael I, foi marcado por muito investimento na ciência baependyana e por uma industrialização fervorosa na capital São Lucas e também em outras capitais provinciais. Vincente começou a investir pesado em Ferrovias e também incentivou empresários a investirem em setores privados. Foi no seu reinado que, no ano de 455, o empresário Céssar de Kenyyu’hs fundou a empresa “MRPE” ou Marinha Real Peixe Estrela. Empresa essa especializada nos ramos civis com a criação de navios lendários como o “Sanktuns” e o navio “Olyympyun”.
      </p>

      <p>
        Ele também promoveu a construção e a melhoria de melhores infraestruturas em todo território nacional, como por exemplo, a construção de melhores estradas para carruagens, deixando as estradas de terra para trás. A construção de uma ferrovia central que ligasse todo o país, desde a capital até as mais longas. Além de investir pesado no exército real, botando treinamentos mais rígidos, porém seriam esses treinamentos que, lá na frente, Baependy mostraria sua potência regional em pancas.
      </p>

      <p>
        Além também de promover a construção de mais navios militares da MRB para patrulhar toda a costa baependyana. E também foi durante seu reinado que ele deu a “Ordem Verde” para todo e qualquer navio catanes que tentasse adentrar a costa de Baependy, para ser afundado sem qualquer aviso para eles se retirarem.
      </p>

      <p>
        Também promoveu investimentos na educação privada e pública, investiu também na construção de escolas especializadas apenas em Música e promoveu a cultura e as artes, além também de se aproximar de nações amigas de Baependy, Nova Harmonia, Nova Carapicuíba e Império Carsilico. Durante os anos de 466 a 480, ele promoveu também uma integração lenta e gradual dos povos indígenas de Monte-Alegre, uma das poucas tribos pacíficas de Baependy. Fez isso com a ajuda dos melhores professores do país para alfabetizá-los e os catequizar no processo, fazendo-os abandonarem suas tradições pagãs. E foi nesse meio tempo que muita miscigenação ocorreu, diversificando muito as raças em Baependy. Mas foi em 31 de junho de 489 que um dos maiores desastres havia ocorrido em Baependy.
      </p>

      <p>
        O Evento dos "Dois Irmãos" foi uma catástrofe nacional que destruiu mais de 70% da capital de Baependy, São Lucas. O evento dos dois irmãos foi um evento onde dois tornados de magnitude EF4 atingiram a capital, destruindo tudo pelo caminho onde passavam. Esse incidente aconteceu no dia 31 de junho de 489, às 07:44 da manhã, em um tempo extremamente nublado. As pessoas não sabiam do que estavam por vir e, quando muitos perceberam, já era tarde demais.
      </p>

      <p>
        Os dois tornados chegaram à capital e começaram a destruir tudo ao redor. Casas foram destruídas, parques e árvores foram arrancadas do chão, a estação de trem foi desmoronada, e a locomotiva real do governo foi arremessada para longe. Mais de 20 mil pessoas morreram nessa tragédia, e outras 5 mil ficaram desaparecidas, muitas delas nunca sendo encontradas até hoje. Toda a população, quando saiu de seus refúgios subterrâneos (os refúgios atuais contra tornados são containers pequenos de 552), percebeu uma capital totalmente devastada pelos irmãos: casas pegaram fogo, muitas árvores foram arremessadas para todas as direções, e pessoas e animais mortos estavam nas ruas. A chuva tomava conta da capital, mas no horizonte, poucos edifícios conseguiram resistir à fúria dos tornados. Porém, três deles ficaram intactos e sem nenhum arranhão, e até hoje não se sabe como conseguiram resistir.
      </p>

      <p>
        Mas foi durante esse trágico evento que a atual bandeira de Baependy, como se conhece, ficou oficializada até os dias atuais. Após a destruição da capital, São Lucas, dias e dias com as pessoas reconstruindo a cidade, muitos artefatos centenários foram encontrados, e um deles era um baú de tamanho médio. Um baú muito bonito para algo tão velho e em bom estado de conservação. O pessoal que havia encontrado esse baú resolveu levá-lo para o rei Vincente I, imaginando que provavelmente pudesse ser dele, já que os tornados também atingiram o palácio real de Serra Verde
      </p>

      <p>
        Quando levaram o baú para o rei, ele disse que não era dele, mas como estava em bom estado de conservação, pediu para que o abrissem. O pessoal que havia encontrado o baú não conseguia abri-lo, já que, por ser muito velho, a tampa estava grudada à base, impossibilitando que fosse aberto. Foi aí então que um engenheiro, que estava fazendo a reforma no palácio, se ofereceu para abrir o baú. Ele, segurando um pé de cabra, conseguiu finalmente abrir o baú.
      </p>

      <p>
        Quando viram o que havia dentro, esperando encontrar dinheiro ou pedras preciosas, não era nada disso que esperavam encontrar. O que encontraram era, na verdade, uma bandeira. Mas não era qualquer bandeira; era a bandeira imperial do Brasil! A mesma que o colonizador Lucas carregava desde os primórdios da colônia. Quando o rei viu a bandeira, não entendeu seus significados e sua história, mas algo na bandeira interessou a ele: a coroa. O formato da coroa era ótimo para seu país, pois Baependy não tinha uma para chamar de sua. E como nenhuma outra nação no mundo de Pindorama tinha uma parecida, o rei ordenou que fizessem essa mesma coroa imediatamente.
      </p>

      <p>
        E assim foi feito. No ano seguinte, em 490, foi feito de forma oficial o evento da coroação do rei Vincente I de Baependy. Finalmente, a nação real de Baependy tinha uma coroa para chamar de sua!
      </p>

      <PageTitle title="Copa do Mundo" level={4} />

      <p>
        No ano de 502, o último ano em que o rei Vincente I apareceria para o público, sua saúde estava muito debilitada e frágil. Ele sentia que sua vida estava acabando, mas não sabia quando isso aconteceria. Mesmo assim, seu país foi convidado para participar da primeira Copa de Pindorama, que iria reunir muitos países ao redor do mundo, sediada em Minuano. Mesmo com a saúde debilitada, o rei não negou a participação de seu país na copa, apesar de esse esporte não ser o favorito dos baependyanos, que preferiam outras modalidades como natação, tiro ao alvo, basquete e justa, sendo essa última muito praticada por militares.
      </p>

      <p>
        Baependy não possuía muitas seleções e times de futebol, mas mesmo assim decidiu participar da copa para ganhar mais notoriedade mundial e alegrar os baependyanos e baependyanas apaixonados por esse esporte. Assim que a copa começou, o rei estava animado para ver um evento inédito que reuniria vários países e líderes para disputar o troféu do vencedor. Ele assistiu ao jogo de Montes Claros contra o Estado de Iracema, onde Montes Claros perdeu. Depois, viu o jogo do Império de Aratigia contra a República de Iracema, em que Aratigia deu uma surra em Iracema.
      </p>

      <p>
        Finalmente chegou a vez de seu país jogar contra seu rival, a República da Terceira Era. Baependy venceu, humilhando o adversário e os mandando de volta para casa. Depois veio Nova Harmonia, que também foi eliminada pela seleção baependyana. Vincente I estava cheio de expectativas para que seu país chegasse às finais, mas infelizmente perdeu para os minuanenses.
      </p>

      <p>
        Mesmo tendo perdido, seu país continuou a assistir ao último jogo, que seria entre Bradualia e Minuano. Minuto a minuto, os torcedores estavam ansiosos para ver quem ganharia. No final, Bradualia venceu, mas, em um momento infeliz, um dos juízes incompetentes decidiu alterar as regras do jogo, gerando tumulto entre os jogadores de ambos os países. Muitos outros países viram isso e repudiaram as atitudes do juiz. O jogo teve que ser refeito e, no final, Minuano saiu vitorioso. Contudo, mesmo com essa vitória, Vincente I não ficou feliz, e, apesar de Baependy não ser aliado de Bradualia, condenou e repudiou a vitória de Minuano, afirmando: "Aquela vitória é suja e fraudulenta!" Essas palavras geraram desavenças entre os minuanenses e o rei, que, percebendo a situação, não quis dialogar para o reconhecimento da vitória na copa. Após isso, a nação de Baependy expulsou os embaixadores de Minuano, que não voltariam até o reinado de João I.
      </p>

      <p>Grandes feitos de Vincente I:</p>

      <ul>
        <li>Impulso na indústria</li>
        <li>Grandes urbanizações nas regiões Sul e Nordeste</li>
        <li>Interligação da Ferrovia Oeste-Leste</li>
        <li>Construção de muitas escolas acadêmicas</li>
        <li>Financiamento para artes e música</li>
        <li>Mais investimentos na medicina</li>
        <li>Promoção de expedições arqueológicas</li>
        <li>Promoção da indústria da pesca</li>
        <li>Redução da carga horária de trabalho, de 12 horas para 8 horas</li>
        <li>Sancionou o voto universal</li>
        <li>Promoveu festivais por todo o país</li>
        <li>Firmou grandes acordos econômicos com Montes Claros para a construção de melhores ferrovias</li>
        <li>Diminuição gradual dos impostos</li>
      </ul>

      <p>
        No ano final de sua vida, em 503, com sua saúde em declínio, ele desejou paz e prosperidade para Baependy, além de muitas glórias no futuro, pedindo que Deus protegesse a nação. Seu título ficou conhecido como "O Rei Amado."
      </p>

      <PageTitle title="Rafael II" level={3} />

      <p>
        Após a morte de Vincente I, começou em 25 de novembro de 503 o 5º Reinado, liderado por Rafael II. Desde o início, Rafael II focou em incentivar a indústria e a área da ciência. Um dos principais motivos para esse investimento era o verão, quando Baependy enfrentava ondas de calor de 35 a 41 graus. Embora esse fosse o padrão para os baependyanos, muitas vezes o calor se tornava tão intenso que as casas ficavam abafadas até para dormir à noite.
      </p>

      <p>
        Foi nesse cenário que um grande inventor se destacou: Frederico de Azevedo. No ano de 506, enquanto Baependy sofria com ondas de calor constantes, ele começou a desenvolver protótipos de máquinas em busca de uma solução para resfriar ambientes. Após muitas tentativas e erros, Frederico conseguiu inventar o ar-condicionado. No começo, o aparelho não era como o que conhecemos hoje; era um tambor gigantesco. No entanto, o importante é que ele tinha alcançado o que muitos almejavam há tanto tempo.
      </p>

      <p>
        Após a invenção de Frederico, a notícia chegou aos ouvidos de Rafael II, que decidiu ver com seus próprios olhos se a criação realmente funcionava. Quando ele experimentou o ar fresco que saía do aparelho, exclamou:
      </p>

      <Quote
        quote="Uma invenção muito nobre, Frederico! Me diga, como você fez isso funcionar? Isso é incrível!!"
        author="Rafael II" />

      <Quote
        quote="Majestade, o ar-condicionado funciona assim: ele pega o calor do ar dentro do seu palácio e o expulsa para fora. O coração do sistema é o compressor, que faz um gás especial ficar quente e sob pressão. Esse gás vai para fora, onde libera o calor e se torna líquido. Depois, ele passa por uma válvula que o resfria bastante e volta para dentro, absorvendo o calor da sala. O ciclo recomeça. O resultado? Um ambiente fresco e confortável para Vossa Majestade."
        author="Frederico" />

      <Quote
        quote="Muito interessante a sua invenção, Frederico! Continue assim, meu amigo. Como reconhecimento por você ter nos salvado dessa onda de calor criando essa invenção, eu te condecoro com a Ordem da Uva. Meus parabéns!"
        author="Rafael II" />

      <Quote
        quote="Obrigado, majestade! Eu que agradeço!"
        author="Frederico" />

      <Quote
        quote="Agora, Frederico, peço que você aprimore e melhore essa invenção. As pessoas merecem ter um desses em suas casas, mesmo que não seja um trambolho gigante. Você receberá nosso financiamento para isso."
        author="Rafael II" />

      <p>E assim, sob o reinado de Rafael II, a inovação e a ciência floresceram, trazendo conforto e qualidade de vida aos baependyanos.</p>

      <p>
        Após aquele dia, ano após ano, Frederico continuou a aprimorar seu ar-condicionado. Os primeiros modelos começaram a ser comercializados em 520, mas ainda eram poucos os que tinham acesso a essa tecnologia, pois o dispositivo precisava de muitas melhorias. Mesmo assim, Frederico deixou um grande legado para a ciência baependyana, e seus conhecimentos continuariam a inspirar futuros cientistas.
      </p>

      <PageTitle title="Naufrágio do Navio Sanktuns" level={4} />

      <p>
        No ano de 509, no dia 4 de fevereiro, foi iniciada a construção do que seria um dos maiores navios de Pindorama. Um dos, porque anos antes a nação de Nova Carapicuíba havia inaugurado o navio "Açu", considerado o maior da época. A empresa Marinha Real Peixe Estrela não aceitaria essa competição e decidiu investir na construção do que seria o maior e mais rápido navio de toda Pindorama. O navio Sanktuns começou a ser construído no porto da capital Corumbá, na província de Tunuí, nos estaleiros do bairro de "Maresouss".
      </p>

      <p>
        A construção do Sanktuns levou três anos. Após ser finalizado em 31 de novembro de 511, ele realizou viagens ao redor de Baependy para garantir que tudo estivesse em conformidade com as normas de segurança da empresa. O navio era equipado com 50 botes salva-vidas para caso ocorresse um naufrágio.
      </p>

      <p>
        Estando tudo dentro das normas de segurança, o dono da empresa, o nobre homem denominado Guilherme Osório da Fonseca
      </p>

      <p>
        Foto dele tirada no dia 05 de maio de 511. Após isso, o navio estava pronto para sarpar rumo a Montes Claros para a sua viagem de inauguração que não só seria uma forma de se aproximar mais de Montes Claros a fim de impulsionar a economia de ambos os países como também seria uma forma de homenagear um dos homens mais respeitados de Baependy, o padre Luís Silva de Baependy.
      </p>

      <p>
        Um dos homens mais dignos de ter feito uma das coisas mais gloriosas para os sofridos baependyanos e baependyanas, ele foi o responsável pelo fim da escravidão em Baependy. Por isso, o navio leva o seu nome em homenagem, já que durante o reinado de Tiago I, após ele ter falecido no ano de 332, a Igreja Católica de Baependy o santificou como forma de agradecer a ele pelos seus grandes feitos e sua servidão em primeiro lugar a Deus.
      </p>
    </div>
  );
}

export default Baependy;