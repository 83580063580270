import './pagelist.css'

import ArticleRedirect from '../../components/ArticleTitles/ArticleRedirect'
import PageTitle from "../../components/ArticleTitles/PageTitle"

function PageList() {
    return (
        <div>
            <PageTitle title="Lista de Páginas" />
            <div className="PageList">

                <div className="PageListBlock">
                    <h2>Nações</h2>

                    <ul>
                        <li><ArticleRedirect to="/aeterna">Aeterna Brasiliense</ArticleRedirect></li>
                        <li><ArticleRedirect to="/aratigia">Aratigia</ArticleRedirect></li>
                        <li><ArticleRedirect to="/baependy">Baependy</ArticleRedirect></li>
                        <li><ArticleRedirect to="/bradualia">Bradualia</ArticleRedirect></li>
                        <li><ArticleRedirect to="/catania">Catânia</ArticleRedirect></li>
                        <li><ArticleRedirect to="/ceruletauria">Ceruletauria</ArticleRedirect></li>
                        <li><ArticleRedirect to="/estadoiracema">Estado de Iracema</ArticleRedirect></li>
                        <li><ArticleRedirect to="/federacaobrasilica">Federação Brasílica</ArticleRedirect></li>
                        <li><ArticleRedirect to="/formosa">Formosa</ArticleRedirect></li>
                        <li><ArticleRedirect to="/guararapes">Guararapes</ArticleRedirect></li>
                        <li><ArticleRedirect to="/harvasquia">Harvásquia</ArticleRedirect></li>
                        <li><ArticleRedirect to="/imperiocarsilico">Império Carsílico</ArticleRedirect></li>
                        <li><ArticleRedirect to="/minuano">Minuano</ArticleRedirect></li>
                        <li><ArticleRedirect to="/montesclaros">Montes Claros</ArticleRedirect></li>
                        <li><ArticleRedirect to="/novabaependy">Nova Baependy</ArticleRedirect></li>
                        <li><ArticleRedirect to="/novabangu">Nova Bangú</ArticleRedirect></li>
                        <li><ArticleRedirect to="/novacambria">Nova Câmbria</ArticleRedirect></li>
                        <li><ArticleRedirect to="/novacarapicuiba">Nova Carapicuíba</ArticleRedirect></li>
                        <li><ArticleRedirect to="/novaharmonia">Nova Harmonia</ArticleRedirect></li>
                        <li><ArticleRedirect to="/novapetropolis">Nova Petropolis</ArticleRedirect></li>
                        <li><ArticleRedirect to="/potiguaro">Potiguaro</ArticleRedirect></li>
                        <li><ArticleRedirect to="/quaqasa">Quaqasa</ArticleRedirect></li>
                        <li><ArticleRedirect to="/reinomundial">Reino Mundial do Poder de Deus</ArticleRedirect></li>
                        <li><ArticleRedirect to="/republicadelvale">República Del Vale</ArticleRedirect></li>
                        <li><ArticleRedirect to="/republicairacema">República de Iracema</ArticleRedirect></li>
                        <li><ArticleRedirect to="/ribati">Ribati</ArticleRedirect></li>
                        <li><ArticleRedirect to="/saocarmo">São Carmo</ArticleRedirect></li>
                        <li><ArticleRedirect to="/terceiraera">Terceira Era</ArticleRedirect></li>
                        <li><ArticleRedirect to="/vasterras">Vasterras</ArticleRedirect></li>
                        <li><ArticleRedirect to="/vaziospolares">Vazios Polares</ArticleRedirect></li>
                    </ul>

                </div>

                <div className="PageListBlock">
                    <h2>Pessoas</h2>

                    <ul>
                        <li><ArticleRedirect to="/alberto_i_de_minuano">Alberto I de Minuano</ArticleRedirect></li>
                    </ul>
                </div>

                <div className="PageListBlock">
                    <h2>Eventos</h2>

                    <ul>
                        <li><ArticleRedirect to="/intervencao_em_guararapes">Intervenção em Guararapes</ArticleRedirect></li>
                    </ul>
                </div>

                <div className="PageListBlock">
                    <h2>Miscelânea</h2>

                    <ul>
                        <li><ArticleRedirect to="/changelog">Changelog</ArticleRedirect></li>
                        <li><ArticleRedirect to="/adminmural">Mural da Administração</ArticleRedirect></li>
                        <li><ArticleRedirect to="/words">B64???</ArticleRedirect></li>
                    </ul>
                </div>

                <div className="PageListBlock">
                    <h2>Organizações Internacionais</h2>

                    <ul>
                        <li><ArticleRedirect to=""></ArticleRedirect></li>
                        <li><ArticleRedirect to=""></ArticleRedirect></li>
                    </ul>
                </div>

                <div className="PageListBlock">
                    <h2>Características Geográficas</h2>
                </div>

            </div>
        </div>
    )
}

export default PageList