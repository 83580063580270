import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';

function SaoCarmo() {
    return (
        <div>
            <PageTitle title="São Carmo" />

            <InfoboxNation
                nome="São Carmo"
                bandeiraSrc={images.saocarmoFlag}
                nomeoficial="Reino de São Carmo"
                capital="São Carmo"
                governo="Monarquia Futebolística"
                religiao="Neo-Brasilicanismo"
                governante="Antonor I de Avaí"
                populacao="73,8 Milhões"
                moeda="Gol"
                idh="-"
                idioma="Carmês">
            </InfoboxNation>
        </div>
    );
}

export default SaoCarmo;