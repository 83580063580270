import PageTitle from "../../components/ArticleTitles/PageTitle"

import './words.css'

function Enigma() {
    return (
        <div>
            <PageTitle title="???" />

            <div className="Poem">
                TyBWZW50byBjYW50YSBwZWxhcyBnw6lsaWRhcyBmbG9yZXN0YXMgZGEgU2liw6lyaWEKQSBpbHVtaW5hw6fDo28gZG8gc<br />
                G9zdGUgZ3JhZHVhbG1lbnRlIHNlIHRvcm5hIGEgZXNjdXJpZMOjbwpBIGNpZGFkZSBlc3TDoSB2YXppYSBjb21vIG8gTm<br />
                9ydGUgYWzDqW0gZGFxdWkKQXBlc2FyIGRlIHR1ZG8gZXUgbsOjbyBwb3NzbyBzYWlyIGRlc3RhIHZlcm1lbGhpZMOjbwoKTyB<br />
                tb21lbnRvIGVzdMOhIENIRUdBTkRPIHLDoXBpZG8KTyBqb3ZlbSBPVVRVQlJPIHRyb3V4ZSBwYXJhIG7Ds3MgYSBtdWRhbsOnYQpEb<br />
                yBnw6lsaWRvIELDoWx0aWNvIGEgaXNvbGFkYSBZZWxpem92bwpKw6EgcGVyZGkgUVVJTlpFIG92ZWxoYXMgYXBlc2FyIGRhIHBlcnNldmVy<br />
                YW7Dp2EKT3MgVEVSUk9SRVMgYXRhY2FtIG1pbmhhIG1lbnRlIGRlIG5vdm8=
            </div>

            <div className="Player">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/1uJ57n8PZ_o?si=_8K1Dtw-IizVUhnj&autoplay=1&loop=1&playlist=1uJ57n8PZ_o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
    )
}
export default Enigma