import './Infobox.css'
import InfoBoxDataSection from './InfoboxDataSection.js';

function InfoboxPerson(props){
    return(
        <div class="InfoBox">

        <header class="InfoBoxHeading">
            <h3>{props.nomeoficial}</h3>
        </header>

        <div class="InfoBoxImg">
            <img width="75%" src={props.bandeiraSrc} alt={`${props.nome} image`}/>
        </div>

        <div class="InfoBoxHeading">
            <h3>Informações Básicas</h3>
        </div>

        <div id="InfoBoxData">
            <InfoBoxDataSection title="Nascimento" content={props.nascimento}/>
            <InfoBoxDataSection title="Morte" content={props.morte}/>
            <InfoBoxDataSection title="Conjuge" content={props.conjuge}/>
            <InfoBoxDataSection title="Dinastia" content={props.dinastia}/>
            <InfoBoxDataSection title="Pai" content={props.pai}/>
            <InfoBoxDataSection title="Mãe" content={props.mae}/>
            <InfoBoxDataSection title="Religião" content={props.religiao}/>
      </div>
    </div>
    )
}

export default InfoboxPerson