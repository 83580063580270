import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';
import InfoboxPerson from '../../components/InfoBox/InfoboxPerson';

function ATKNK() {
  return (
    <div>
      <PageTitle title="ATKNK"/>
        
      <InfoboxPerson
        nascimento="30 de Novembro de 2005"
        morte="?"
        conjuge="-"
        dinastia=""
        pai=""
        mae=""
        religiao="Catolicismo"
      />

      ATKNK é o desenvolvedor da wiki, só isso mesmo.
    </div>
  );
}

export default ATKNK;