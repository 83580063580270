import './Quote.css'

function Quote(props){
    return(
        <div className="QuoteDiv">
             "<span className="Quote">{props.quote}</span>"
            <br/> -<span className="Author">{props.author}</span>
        </div>
    )
}

export default Quote