import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';

function Vasterras() {
  return (
    <div>
      <PageTitle title="Vasterras">
        
      </PageTitle>
      <InfoboxNation
          nome="Vasterras"
          bandeiraSrc={images.vasterrasFlag}
          nomeoficial="Sacro Reino de Vasterras"
          capital="Quinta da Boa Vista"
          governo="Monarquia Absolutista"
          religiao="Ortodoxismo"
          governante="Vegetti São Estevão de Vásquia III"
          populacao="68,3 Milhões"
          moeda="Dobrão Vásquio"
          idh="-"
          idioma="Luso-Vastérrio">
        </InfoboxNation>
    </div>
  );
}

export default Vasterras;