import React from "react";
import { Link } from "react-router-dom";

function ArticleRedirect(props){
    return(
        <Link to={props.to} className="ArticleRedirect">
            {props.children}
        </Link>
    );
}

export default ArticleRedirect;