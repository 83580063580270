import React from "react";
import { Routes, Route } from "react-router-dom";

import Changelog from "./misc/changelog";
import AdminMural from "./misc/adminmural";
import PageList from "./misc/pagelist";
import Words from "./misc/words";
import Enigma from "./misc/enigma";

const miscRoutes = [
    { path: "/changelog", element:<Changelog/>},
    { path: "/adminmural", element:<AdminMural/>},
    { path: "/pagelist", element:<PageList/>},
    { path: "/words", element:<Words/>},
    { path: "/enigma", element:<Enigma/>}
];

export default miscRoutes;