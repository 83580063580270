import ArticleRedirect from "../../components/ArticleTitles/ArticleRedirect"
import PageTitle from "../../components/ArticleTitles/PageTitle"

import './words.css'

function Words() {
    return (
        <div className="Words">
            <PageTitle title="???" />

            <div className="WordList">
                <div >
                    <ul>
                        <li><ArticleRedirect>Mercúrio</ArticleRedirect></li>
                        <li><ArticleRedirect>Atlas</ArticleRedirect></li>
                        <li><ArticleRedirect>Europa</ArticleRedirect></li>
                        <li><ArticleRedirect>Zeus</ArticleRedirect></li>
                        <li><ArticleRedirect>Atena</ArticleRedirect></li>
                        <li><ArticleRedirect>Gaia</ArticleRedirect></li>
                        <li><ArticleRedirect>Marte</ArticleRedirect></li>
                        <li><ArticleRedirect>Poseidon</ArticleRedirect></li>
                        <li><ArticleRedirect>Helios</ArticleRedirect></li>
                        <li><ArticleRedirect>Apolo</ArticleRedirect></li>
                        <li><ArticleRedirect>Minotauro</ArticleRedirect></li>
                        <li><ArticleRedirect>Afrodite</ArticleRedirect></li>
                        <li><ArticleRedirect>Dionísio</ArticleRedirect></li>
                        <li><ArticleRedirect>Diógenes</ArticleRedirect></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li><ArticleRedirect>Manganês</ArticleRedirect></li>
                        <li><ArticleRedirect>Seabórgio</ArticleRedirect></li>
                        <li><ArticleRedirect>Fósforo</ArticleRedirect></li>
                        <li><ArticleRedirect>Hélio</ArticleRedirect></li>
                        <li><ArticleRedirect>Prata</ArticleRedirect></li>
                        <li><ArticleRedirect>Rutênio</ArticleRedirect></li>
                        <li><ArticleRedirect>Irídio</ArticleRedirect></li>
                        <li><ArticleRedirect>Enxofre</ArticleRedirect></li>
                        <li><ArticleRedirect>Telúrio</ArticleRedirect></li>
                        <li><ArticleRedirect>Argônio</ArticleRedirect></li>
                        <li><ArticleRedirect>Einstênio</ArticleRedirect></li>
                        <li><ArticleRedirect>Rutherfórdio</ArticleRedirect></li>
                        <li><ArticleRedirect>Háfnio</ArticleRedirect></li>
                        <li><ArticleRedirect>Hélio</ArticleRedirect></li>
                    </ul>
                </div>

                <div >
                    <ul>
                        <li><ArticleRedirect>Laranja</ArticleRedirect></li>
                        <li><ArticleRedirect>Amianto</ArticleRedirect></li>
                        <li><ArticleRedirect>Eletromagnetismo</ArticleRedirect></li>
                        <li><ArticleRedirect>Rato</ArticleRedirect></li>
                        <li><ArticleRedirect>Cervo</ArticleRedirect></li>
                        <li><ArticleRedirect>Rádio</ArticleRedirect></li>
                        <li><ArticleRedirect>Borgonha</ArticleRedirect></li>
                        <li><ArticleRedirect>Processador</ArticleRedirect></li>
                        <li><ArticleRedirect>Fio Elétrico</ArticleRedirect></li>
                        <li><ArticleRedirect>Macarronada</ArticleRedirect></li>
                        <li><ArticleRedirect>Corneta</ArticleRedirect></li>
                        <li><ArticleRedirect>Pinheiro</ArticleRedirect></li>
                        <li><ArticleRedirect>Alga-Marinha</ArticleRedirect></li>
                        <li><ArticleRedirect>Impermeabilidade</ArticleRedirect></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li><ArticleRedirect>Erva-mate</ArticleRedirect></li>
                        <li><ArticleRedirect>Caçari</ArticleRedirect></li>
                        <li><ArticleRedirect>Palmeira</ArticleRedirect></li>
                        <li><ArticleRedirect>Orquídea</ArticleRedirect></li>
                        <li><ArticleRedirect>Vitória-Régia</ArticleRedirect></li>
                        <li><ArticleRedirect>Girassol</ArticleRedirect></li>
                        <li><ArticleRedirect>Peônia</ArticleRedirect></li>
                        <li><ArticleRedirect>Araucária</ArticleRedirect></li>
                        <li><ArticleRedirect>Aloe Vera</ArticleRedirect></li>
                        <li><ArticleRedirect>Gardênia</ArticleRedirect></li>
                        <li><ArticleRedirect>Bambu</ArticleRedirect></li>
                        <li><ArticleRedirect>Pitanga</ArticleRedirect></li>
                        <li><ArticleRedirect>Violeta</ArticleRedirect></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li><ArticleRedirect>Paranaguá</ArticleRedirect></li>
                        <li><ArticleRedirect>Nova Roma</ArticleRedirect></li>
                        <li><ArticleRedirect>Natal</ArticleRedirect></li>
                        <li><ArticleRedirect>São João Del Rey</ArticleRedirect></li>
                        <li><ArticleRedirect>Cariacica</ArticleRedirect></li>
                        <li><ArticleRedirect>Aparecida de Goiânia</ArticleRedirect></li>
                        <li><ArticleRedirect>Pacaraima</ArticleRedirect></li>
                        <li><ArticleRedirect>Normandia</ArticleRedirect></li>
                        <li><ArticleRedirect>Guarulhos</ArticleRedirect></li>
                        <li><ArticleRedirect to="/enigma">Omsk</ArticleRedirect></li>
                        <li><ArticleRedirect>Feijó</ArticleRedirect></li>
                        <li><ArticleRedirect>Xique-Xique</ArticleRedirect></li>
                        <li><ArticleRedirect>Teresina</ArticleRedirect></li>
                    </ul>
                </div>


                <div>
                    <ul>
                        <li><ArticleRedirect>Cassarola</ArticleRedirect></li>
                        <li><ArticleRedirect>Lasanha</ArticleRedirect></li>
                        <li><ArticleRedirect>Almôndega</ArticleRedirect></li>
                        <li><ArticleRedirect>Feijoada</ArticleRedirect></li>
                        <li><ArticleRedirect>Bolo</ArticleRedirect></li>
                        <li><ArticleRedirect>Sushi</ArticleRedirect></li>
                        <li><ArticleRedirect>Sanduíche</ArticleRedirect></li>
                        <li><ArticleRedirect>Cachorro-Quente</ArticleRedirect></li>
                        <li><ArticleRedirect>Uva-Passa</ArticleRedirect></li>
                        <li><ArticleRedirect>Escondidinho</ArticleRedirect></li>
                        <li><ArticleRedirect>Goiabada</ArticleRedirect></li>
                        <li><ArticleRedirect>Strogonoff</ArticleRedirect></li>
                        <li><ArticleRedirect>Frango Assado</ArticleRedirect></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li><ArticleRedirect>Scanner</ArticleRedirect></li>
                        <li><ArticleRedirect>Canhão Repulsor</ArticleRedirect></li>
                        <li><ArticleRedirect>Ferramenta de Reparo</ArticleRedirect></li>
                        <li><ArticleRedirect>Seaglide</ArticleRedirect></li>
                        <li><ArticleRedirect>Cyclops</ArticleRedirect></li>
                        <li><ArticleRedirect>Seamoth</ArticleRedirect></li>
                        <li><ArticleRedirect>Traje Prawn</ArticleRedirect></li>
                        <li><ArticleRedirect>Extintor de Incêndio</ArticleRedirect></li>
                        <li><ArticleRedirect>Cortador a Laser</ArticleRedirect></li>
                        <li><ArticleRedirect>Lanterna</ArticleRedirect></li>
                        <li><ArticleRedirect>Cápsula do Tempo</ArticleRedirect></li>
                        <li><ArticleRedirect>Construtor de Habitat</ArticleRedirect></li>
                        <li><ArticleRedirect>Tábula Azul</ArticleRedirect></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li><ArticleRedirect>Tambaqui</ArticleRedirect></li>
                        <li><ArticleRedirect>Pirarucu</ArticleRedirect></li>
                        <li><ArticleRedirect>Bagre</ArticleRedirect></li>
                        <li><ArticleRedirect>Tilápia</ArticleRedirect></li>
                        <li><ArticleRedirect>Baiacu</ArticleRedirect></li>
                        <li><ArticleRedirect>Barrigudinho</ArticleRedirect></li>
                        <li><ArticleRedirect>Piranha</ArticleRedirect></li>
                        <li><ArticleRedirect>Carpa</ArticleRedirect></li>
                        <li><ArticleRedirect>Candiru</ArticleRedirect></li>
                        <li><ArticleRedirect>Enguia</ArticleRedirect></li>
                        <li><ArticleRedirect>Peixe-Leão</ArticleRedirect></li>
                        <li><ArticleRedirect>Peixe-Boi</ArticleRedirect></li>
                        <li><ArticleRedirect>Linguado</ArticleRedirect></li>
                    </ul>
                </div>

                <div>
                    <ul>
                        <li><ArticleRedirect>4546b</ArticleRedirect></li>
                        <li><ArticleRedirect>Mustafar</ArticleRedirect></li>
                        <li><ArticleRedirect>Alderaan</ArticleRedirect></li>
                        <li><ArticleRedirect>Coruscant</ArticleRedirect></li>
                        <li><ArticleRedirect>Jool</ArticleRedirect></li>
                        <li><ArticleRedirect>Endor</ArticleRedirect></li>
                        <li><ArticleRedirect>Tatooine</ArticleRedirect></li>
                        <li><ArticleRedirect>Duna</ArticleRedirect></li>
                        <li><ArticleRedirect>Kamino</ArticleRedirect></li>
                        <li><ArticleRedirect>Kepler 22b</ArticleRedirect></li>
                        <li><ArticleRedirect>Netuno</ArticleRedirect></li>
                        <li><ArticleRedirect>Júpiter</ArticleRedirect></li>
                        <li><ArticleRedirect>Marte</ArticleRedirect></li>
                    </ul>
                </div>
            </div>

            <div className="Player">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/K1qjt_1geiI?si=j1x8lWPrA_VUxD6m&autoplay=1&loop=1&playlist=K1qjt_1geiI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>



        </div>


    )
}

export default Words