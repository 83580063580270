import InfoboxNation from '../../components/InfoBox/InfoboxNation';
import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';
import InfoboxPerson from '../../components/InfoBox/InfoboxPerson';
import ArticleRedirect from '../../components/ArticleTitles/ArticleRedirect';

function AlbertoIDeMinuano() {
  return (
    <div>
      <PageTitle title="Alberto I de Minuano"/>
        
      <InfoboxPerson
        nascimento="19 de Novembro de 527"
        morte=""
        conjuge="Anna Cristina de Ottoni-Ansbach"
        dinastia="Córdoba-Luna"
        pai="Marcelo IV de Córdoba e Luna"
        mae="Carlota de Dourados"
        religiao="Catolicismo"
      />

      <p>
        Alberto I de Córdoba e Luna é o atual Imperador da <ArticleRedirect to="/minuano">Confederação Imperial de Minuano</ArticleRedirect>. Tendo sido eleito na Eleição Imperial
        de 551 após a morte de seu antecessor Conrado III.
      </p>

      <PageTitle title="Primeiros Anos" level={2}/>

      <p>
        O então Príncipe de Córdoba e Marquês de Luna Alberto IV nasceu em Córdoba em 527, sendo um dos descendentes do general Alberto de Luna. Passou boa parte de sua infância estudando
        táticas militares e logística.
      </p>
    </div>
  );
}

export default AlbertoIDeMinuano;