import React from "react";

import AlbertoIDeMinuano from "./person/alberto_i_de_minuano";

import ATKNK from "./person/atknk";
import Zees from "./person/zees";

const personRoutes=[
    { path: "/alberto_i_de_minuano", element: <AlbertoIDeMinuano /> },

    {path: "/atknk", element: <ATKNK/> },
    {path: "/zees", element: <Zees/>}
];

export default personRoutes;