import './adminmural.css'

import PageTitle from "../../components/ArticleTitles/PageTitle"
import MemberBox from '../../components/MiscBoxes/MemberBox';

import { images } from '../../components/Images/ImagesSrc';

function AdminMural() {
    return (
        <div>
            <PageTitle title="A Administração do Projeto" />

            <div className='Admins'>

                <MemberBox
                    nome="ATKNK"
                    flag={images.minuanoFlag}
                    to={"/minuano"}
                    funcao="Diretor Geral"
                    img={images.atk}
                    desc=""
                    msg="Eu realmente não sei o que escrever aqui."
                />

                <MemberBox
                    nome="Carreta"
                    flag={images.montesclarosFlag}
                    to={"/montesclaros"}
                    funcao="Revisor de Lore"
                    img={images.carreta}
                    desc=""
                    msg="CRUZEIRO!!!!"
                />

                <MemberBox
                    nome="Roraima"
                    flag={images.vasterrasFlag}
                    to={"/vasterras"}
                    funcao="Coordenador de Lore"
                    img={images.roraima}
                    desc=""
                    msg="Tudo nosso nada deles"
                />

                <MemberBox
                    nome="Empire"
                    flag={images.baependyFlag}
                    to={"/baependy"}
                    funcao="Revisor de Astronomia"
                    img={images.empire}
                    desc=""
                    msg="Nunca desafie o almirante, você não sabe o que ele vai fazer com você!"
                />

                <MemberBox
                    nome="Deyvid"
                    flag={images.novaharmoniaFlag}
                    to={"/novaharmonia"}
                    funcao="Diplomata do Projeto"
                    img={images.deyvid}
                    desc=""
                    msg="Não se faz um pão sem farinha"
                />

                <MemberBox
                    nome="Zees"
                    flag={images.vaziospolaresFlag}
                    to={"/vaziospolares"}
                    funcao="Moderador do Chat"
                    img={images.zees}
                    desc=""
                    msg="Odeio COD Vanguard"
                />




            </div>
        </div>
    );
}

export default AdminMural