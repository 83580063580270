import React from "react";
import { Routes, Route } from "react-router-dom";

import nationRoutes from "./nationPages";
import eventRoutes from "./eventPages";
import personRoutes from "./personPages";
import miscRoutes from "./miscPages";
import organizationRoutes from "./organizationPages";

import MainPage from "./mainpage";

function RoutesCentral(){
    return(
        <Routes>
        <Route path="/" element={<MainPage />} />

        {nationRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        {eventRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        {personRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}/>
        ))}
        {miscRoutes.map((route, index) =>(
          <Route key={index} path={route.path} element={route.element}/>
        ))}
        {organizationRoutes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element}/>
        ))}
      </Routes>
    );
}

export default RoutesCentral;