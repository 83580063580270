import React from "react";
import './Header.css';

function ThemeButton({ icon, onClick, altText}) {
    return (
        <button className="ThemeButton" onClick={onClick}>
            <img src={icon} alt={altText} width="50" height="50"/>
        </button>
    );
}

export default ThemeButton;