import './Article.css'
import ArticleRedirect from './ArticleRedirect';

function MainArticle(props) {
    return (
        <div className="Subtitle">
            <span id="SeeMainArticle">
                {props.text} <ArticleRedirect to={props.to}>{props.linkText}</ArticleRedirect>
            </span>
        </div>
    );
}

export default MainArticle;