import ImageGallery from '../../components/ImageGallery/ImageGallery';
import PageTitle from '../../components/ArticleTitles/PageTitle';

import { images } from '../../components/Images/ImagesSrc';
import InfoboxPerson from '../../components/InfoBox/InfoboxPerson';

function Zees() {
  return (
    <div>
      <PageTitle title="Zees"/>
        
      <InfoboxPerson
        nascimento="algum dia de Fevereiro de 2005"
        morte="Maria Fedida entrou no reto"
        conjuge="Deyvid"
        dinastia="Família do Marnet"
        pai="Carreta (adotivo)"
        mae="Roraima (adotivo)"
        religiao="Alguma coisa ae"
      />

      Zees é um corno da wiki, só isso mesmo.
    </div>
  );
}

export default Zees;